import React from 'react'
import {
  CButton,
  CInputGroupPrepend,
  CInputGroupText,
} from '../../MyCore';
import HText from '../../../helpers/Text';
import HOptions from '../../../helpers/Options';

class MyTableFilter_Ext extends React.Component {    
  getInitValue=(df,opts)=>{//defaultvalue, savedvalue
    const {fieldName,fnList} = this.props;
    const {getInitValuesFilter,checkIsLastItem,submitCurrentFilter} = fnList;
    if(checkIsLastItem){
      this._isLast = checkIsLastItem();     
    }
    // console.warn("getInitValue",this._isLast,this.props);
    if(getInitValuesFilter){
      let _initValuesFilter = getInitValuesFilter();      
      if(_initValuesFilter && _initValuesFilter[fieldName]!=null){
        if(opts && opts.onChangeWithCurrentValues){
          opts.onChangeWithCurrentValues(_initValuesFilter[fieldName],this._isLast);
        }
        else{
          this.onChange(_initValuesFilter[fieldName],{
            justUpdateFilter: this._isLast===true?false:true,
            fromInit: true,
          });
        }        
        return _initValuesFilter[fieldName];
      }
    }    
    // console.warn("getInitValue",df,this._isLast,this.props);
    if(this._isLast===true && submitCurrentFilter){      
      submitCurrentFilter({
        fromDefaultValue: true
      });
    }        
    return df;
  }
  getFieldName=()=>{
    const {fieldName} = this.props;
    return fieldName;
  }
  getType=()=>{
    const {type} = this.props;
    return type;
  }
  getTitle=()=>{
    const {title} = this.props;
    const config = this.getConfigShared();
    if(config.ui.iconTitle){
      return (
        <i className={config.ui.iconTitle}></i>
      )
    }
    return title;
  }
  getOptionKey=()=>{
    const {optionKey} = this.props;
    return optionKey;
  }
  getIsUsingOptionInList=()=>{
    const {isUsingOptionInList,optionKey} = this.props;
    if(optionKey && optionKey.toLowerCase().indexOf('inlist')>-1){
      return true;
    }
    return isUsingOptionInList;
  }
  getSourceList=()=>{
    const {_getSourceList,_getDataList} = this.props;
    if(_getSourceList){
      return _getSourceList();
    }
    return {};
  }
  _getDataList=()=>{ // có trường hợp truyền object {ExtraData và Data}
    const {_getDataList} = this.props;
    let _data = _getDataList();
    let _result = []
    if(_data){
      if(Array.isArray(_data) === true){
        _result = _data
      }
      else if(typeof(_data) === "object"){
        if(_data.hasOwnProperty("Data") === true){
          _result = _data.Data
        }
      }
    }
    return _result
  }
  getSourceForSelect=(opts)=>{
    const {_getDataList} = this.props;
    let _sourceList = this.getSourceList();
    let _fieldName = this.getFieldName();
    let _optionKey = this.getOptionKey();
    let _isUsingInList = this.getIsUsingOptionInList();
    // console.warn("_isUsingInList:",_isUsingInList);
    if(_isUsingInList){
      let _data = this._getDataList();
      // console.warn("_data:",_data,_fieldName,opts);
      if(_data){
        return HOptions.createListDataFromFieldNameOfDataList(_fieldName,_data,opts);
      }
    }
    else if(_optionKey && _sourceList && _sourceList[_optionKey]){
      // if(opts && opts.isSelect2==true && opts.needConvert==true){//ko can convert nua
      //   return HOptions.convertOptionsApiToSelect2({sourceList:_sourceList[_optionKey]})
      // }
      return _sourceList[_optionKey];
    }
    return [];
  }
  getFilter=(value,more,opts)=>{
    return this.getFilterForBootstrapTable(value,more);
  }
  getFilterForBootstrapTable=(value,more)=>{
    let _obj = {
      isServer: this.getMoreOfKey("isServer",false),      
      value: value,
      ...more//type
    }
    let _fQuery = this.getMoreOfKey("fQuery","");
    if(_fQuery){
      _obj.fQuery = _fQuery;
    }
    let _fieldQuery = this.getMoreOfKey("fieldQuery","");
    if(_fieldQuery){
      _obj.fieldQuery = _fieldQuery;
    }
    let _fQueryStart = this.getMoreOfKey("fQueryStart","");
    if(_fQueryStart){
      _obj.fQueryStart = _fQueryStart;
    }
    let _fQueryEnd = this.getMoreOfKey("fQueryEnd","");
    if(_fQueryEnd){
      _obj.fQueryEnd = _fQueryEnd;
    }
    return  _obj;
  }
  getMore=()=>{
    const {more,configOne} = this.props;
    if(configOne && configOne._moreObj){
      return configOne._moreObj;
    }
    if(typeof more=="object"){
      return more;
    }
    else if(typeof more=="string"){
      try {
        let _obj = JSON.parse(more);        
        if(configOne){
          configOne._moreObj = _obj;
        }
        return _obj;
      } catch (error) {
        console.warn("parse json more error",more);
      }
    }
    // console.warn("type more need a object",more,configOne);
    return {};
  }
  getMoreOfKey=(key,df)=>{
    const more = this.getMore();
    // console.warn("getMoreOfKey:",key,more);
    if(more[key]){
      return more[key];
    }
    return df;
  }
  getMoreControlProps=()=>{
    const {moreProps} = this.props;
    let _more = {};
    if(moreProps){
      _more = Object.assign(_more,moreProps.controlProps,moreProps.selectProps);
    }
    return _more;
  }
  getConfigShared=({placeholder}={})=>{
    if(this._configShared){
      return this._configShared;
    }
    this._configShared = {
      ui:{
        showPre:true,
        showSuffix: true,
        showClear: true,
        iconTitle: null,
        ...this.getMoreOfKey("ui",{})
      },
      placeholder: this.getMoreOfKey("placeholder",placeholder||HText.get('text_enter_keyword')),
    }
    return this._configShared;
  }
  getConfigFromConfigOneByKey=(key, df)=>{
    const {configOne} = this.props;
    let _cf = df;
    if(configOne && configOne[key]){
      _cf = configOne[key];
    }
    return _cf
  }
  onChange=(value,opts={})=>{
    const {onChange} = this.props;
    // console.warn("onChange:",value);
    if(onChange){
      let _opts = {
        filter: this._getFilter(value),
        ...opts
      };
      console.warn("checkbox _onChange:",value,_opts);
      onChange(value,_opts);
    }
  }

  renderPre=()=>{
    const config = this._getConfig();
    const title = this.getTitle();
    const styleTitle = this.getMoreOfKey('styleTitle') || {};
    // console.warn("renderPre:",styleTitle);
    if(config.ui.showPre && title!=null && title!=""){
      return (
        <CInputGroupPrepend>
          <CInputGroupText style={styleTitle}>{title}</CInputGroupText> 
        </CInputGroupPrepend>
      )
    }
    return null;
  }
  renderClear=()=>{
    const config = this._getConfig();
    if(config.ui.showClear && this.state.value!=null && this.state.value.length>0){
      return (
        <div>
          <CButton color="danger" style={{border:"0px"}} outlined onClick={()=>{
            this.setState({
              value: '',
            },()=>{
              this.onChange(null);
            })
          }}><i className="fa fa-ban"></i></CButton>
        </div>
      )
    }
    return null;
  }
  render() {
    return(
      <div></div>
    )
  }
}

export default MyTableFilter_Ext