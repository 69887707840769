
import React from 'react'
import MyTableCellExt from './_ext';
import MyTableCellHelpers from '../MyTableCellHelpers';
import MyTableCellReadOnly from './readonly';
import {MyUI} from '../../../apps/Components';

class MyTableCell_Checkbox extends MyTableCellExt {
  _getMyClass=()=>{
    return "mct-checkbox";
  }    
  renderContent=()=>{
    const {row,cell} = this.props;
    const canEdit = MyTableCellHelpers.getCanEdit(this);
    const className = MyTableCellHelpers.getClassOfCell(this);

    return (
      <MyUI 
        type="input_check" 
        defaultValue={cell} 
        disabled={!canEdit}
        style={MyTableCellHelpers.getStyleOfCell(this)}
        className={className}
        onChange={(ev,newValue)=>{          
          MyTableCellHelpers.callRequestUpdate(this,{newValue});
        }}
      />
    )
  } 
}

export default MyTableCell_Checkbox