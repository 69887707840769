import React from 'react'
// import With_MyUI from './Types/with_myui';
// const Html = React.lazy(() => import('./Types/html'));
// const ReadonlyHtml = React.lazy(() => import('./Types/readonly_html'));
// const Readonly = React.lazy(() => import('./Types/readonly'));
// const Text = React.lazy(() => import('./Types/text'));
// const Col1 = React.lazy(() => import('./Types/_col1'));
// const Textarea = React.lazy(() => import('./Types/textarea'));
// const Checkbox = React.lazy(() => import('./Types/checkbox'));
// const Select = React.lazy(() => import('./Types/select'));
// const Link = React.lazy(() => import('./Types/link'));
// const Button = React.lazy(() => import('./Types/button'));
// const Date = React.lazy(() => import('./Types/date'));
// const Datetime = React.lazy(() => import('./Types/datetime'));
// const Delete = React.lazy(() => import('./Types/delete'));
// const ReadonlyDate = React.lazy(() => import('./Types/readonly_date'));
// const ReadonlyDateTime = React.lazy(() => import('./Types/readonly_datetime'));
// const Expand = React.lazy(() => import('./Types/expand'));
// const Select2Modal = React.lazy(() => import('./Types/select2_modal'));
// const ReadonlyArray = React.lazy(() => import('./Types/readonly_array'));
// const Pickcolor = React.lazy(() => import('./Types/pickcolor'));
// const Textareafull = React.lazy(() => import('./Types/textareafull'));
// const ArrayEditable = React.lazy(() => import('./Types/array_editable'));
// const Files = React.lazy(() => import('./Types/files'));

const MyTableCellTypes = {
  addTypes(key,fnUI){
    if(MyTableCellTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyTableCellTypes.Types[key]=fnUI;
    }
  },
  Types:{
    // _col1:(props)=>{ return ( <Col1 {...props}/> ) },
    // with_myui:(props)=>{ return ( <With_MyUI {...props}/> ) },
    // html:(props)=>{ return ( <Html {...props}/> ) },
    // readonly_html:(props)=>{ return ( <ReadonlyHtml {...props}/> ) },
    // readonly:(props)=>{ return ( <Readonly {...props}/> ) },
    // text:(props)=>{ return ( <Text {...props}/> ) },
    // textarea:(props)=>{ return ( <Textarea {...props}/> ) },
    // checkbox:(props)=>{ return ( <Checkbox {...props}/> ) },
    // select:(props)=>{ return ( <Select {...props}/> ) },
    // link:(props)=>{ return ( <Link {...props}/> ) },
    // api:(props)=>{ return ( <Button {...props}/> ) },
    // date: (props)=>{ return ( <Date {...props}/> ) },
    // datetime: (props)=>{ return ( <Datetime {...props}/> ) },
    // delete: (props)=>{ return ( <Delete {...props}/> ) },
    // readonly_date:(props)=>{ return ( <ReadonlyDate {...props}/> ) },
    // readonly_datetime:(props)=>{ return ( <ReadonlyDateTime {...props}/> ) },
    // expand: (props)=>{ return ( <Expand {...props}/> ) },
    // expand_api: (props)=>{ return ( <Expand {...props}/> ) },
    // expand_multi: (props)=>{ return ( <Expand {...props}/> ) },
    // select2_modal:(props)=>{ return ( <Select2Modal {...props}/> ) },
    // readonly_array:(props)=>{ return ( <ReadonlyArray {...props}/> ) },
    // array_editable_modal:(props)=>{ return ( <ReadonlyArray {...props}/> ) },
    // pickcolor: (props)=>{ return ( <Pickcolor {...props}/> ) },
    // textareafull:(props)=>{ return ( <Textareafull {...props}/> ) },
    // array_editable:(props)=>{ return ( <ArrayEditable {...props}/> ) },
    // files:(props)=>{ return ( <Files {...props}/> ) },
  }
}
window.MyTableCellTypes = MyTableCellTypes;
export default MyTableCellTypes;