import React from 'react'
import './style.css'

class ImageZoom extends React.Component {
    static propTypes = {}
    static defaultProps = {
        zoom: 3,
        idImgZoom: "zoom"
    }
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    getConfigZoom=()=>{
        const {idImgZoom, zoom} = this.props;
        let _idImgZoom = idImgZoom || this.defaultProps.idImgZoom;
        let _zoom = zoom || this.defaultProps.zoom;
        return{
            zoom: _zoom,
            idImgZoom: _idImgZoom
        }
    }
    onZoomOutImg=()=>{
        const {idImgZoom} = this.props;
        const imgCloudZoom = document.querySelector(`#${idImgZoom}`);
        if(imgCloudZoom){
            imgCloudZoom.remove()
        }
    }
    onMouseMoveEventHandle=()=>{
        const {imgOrigin} = this.props;
        const img = document.getElementById(imgOrigin.id);        
        if(img){
            const {idImgZoom} = this.getConfigZoom()
            let imgCloudZoom = document.getElementById(idImgZoom);
            if(!imgCloudZoom){
                imgCloudZoom = document.createElement("div");
                imgCloudZoom.id = idImgZoom;
                imgCloudZoom.className = "cloudimage-zoomin";
                img.parentElement.insertBefore(imgCloudZoom, img);
                imgCloudZoom.addEventListener("mousemove", this.onZoomInImg);
                imgCloudZoom.addEventListener("mouseleave", this.onZoomOutImg);
            }
        }
    }
    onZoomInImg=(e)=>{
        const {imgOrigin} = this.props;
        const img = document.getElementById(imgOrigin.id);        
        if(img){
            const {idImgZoom, zoom} = this.getConfigZoom()
            let imgCloudZoom = document.getElementById(idImgZoom);
            if(!imgCloudZoom){
                imgCloudZoom = document.createElement("div");
                imgCloudZoom.id = idImgZoom;
                imgCloudZoom.className = "cloudimage-zoomin";
                img.parentElement.insertBefore(imgCloudZoom, img);
            }
            imgCloudZoom.style.backgroundImage = "url('" + img.src + "')";
            imgCloudZoom.style.backgroundRepeat = "no-repeat";
            imgCloudZoom.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px"; 

            const img_position = img.getBoundingClientRect();
            const body = document.body.getBoundingClientRect();
            let x = e.pageX - img_position.left;
            let y = e.pageY - Math.abs(img_position.top-body.top);//lay gia tri tuyet toi de tinh y cho dung

            let _mx = x*zoom - x;
            let _my = y*zoom - y;

            imgCloudZoom.style.backgroundPositionX = "-" + _mx + "px";
            imgCloudZoom.style.backgroundPositionY = "-" + _my + "px";
        }
    }
  render(){
    const {imgOrigin} = this.props;
    if(imgOrigin){
        return (
            <img src={imgOrigin.src} 
            id={imgOrigin.id} 
            className={imgOrigin.className} 
            style={{userSelect: "none", width: '540px'}} 
            onMouseEnter={(e)=>this.onMouseMoveEventHandle(e)} 
        />
        )
    }
    return <div></div>
  }
}

export default ImageZoom