import React from 'react';

const Options = {
    createListDataFromFieldNameOfDataList(fieldName,list,opts={}){
      // console.warn('createListDataFromFieldNameOfDataList:',fieldName,list,opts);
      let _arr = [];
      if(fieldName && list && list.length>0){
        let _arrValue = [];
        let _keyValue = 'Value';
        let _keyText = 'Text';
        if(opts){
          if(opts.keyText){
            _keyText = opts.keyText;
          }
          if(opts.keyValue){
            _keyValue = opts.keyValue;
          }
          // if(opts.isSelect2){//ko can convert nua
          //   _keyValue = 'value';
          //   _keyText = 'label';
          // }
        }
        for(let i in list){
          let _item = list[i];
          let _value = _item[fieldName];
          if(_arrValue.indexOf(_value)==-1 && _value){
            let _obj = {};
            _obj[_keyText] = _value;
            _obj[_keyValue] = _value;
            if(opts.fnGetText){
              _obj[_keyText] = opts.fnGetText(_value);
            }
            _arr.push(_obj);
            _arrValue.push(_value);
          }        
        }
      }
      // console.warn("arr:",_arr);
      return _arr;
    },
    convertOptionsApiToSelect2({sourceList}){
      let _sourceList = [];
      if(sourceList!=null && sourceList.length>0){
        _sourceList = sourceList.map((e,i)=>{
          return {value: e.Value, label: e.Text};
        })
      }
      return _sourceList;
    },
    createOptionsForSelect(options,msgEmpty){
        let _ui = [];
        if(options!=null){
          if(msgEmpty!=null){
            _ui.push(
              <option key={-1} value={""}>{msgEmpty}</option>
            );
          }
          for(let i=0;i<options.length;i++){
            _ui.push(
              <option key={i} value={options[i].Value}>{options[i].Text}</option>
            );
          }
        }
        return _ui;
    },
    getTextDisplayOfValueFromOptions(options,value){
      let _text = '';
      if(options!=null){
        for(let i=0;i<options.length;i++){
          if(options[i].Value==value){
            _text = options[i].Text_Display || options[i].Text;
            break;
          }
        }
      }
      return _text;
    },
  }
  export default Options;