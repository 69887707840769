import React from 'react'
import MyLoadingComponent from '../MyLoading/MyLoadingComponent';
import PageWithUI from './Types/WithMyUI';
import PageGenericUrl from './Types/Generic_Url'
// const PagePanel = React.lazy(() => import('./Types/Panel'));
// const PageTable = React.lazy(() => import('./Types/Table'));
// const PageError = React.lazy(() => import('./Types/Error'));
// const PageTab = React.lazy(() => import('./Types/Tab'));
// const PageDashboard = React.lazy(() => import('./Types/Dashboard'));

const MyPageTypes = {
  addTypes(key,fnUI){
    if(MyPageTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyPageTypes.Types[key]=fnUI;
    }
  },
  Types:{
    test:(props)=>{ return ( <div>Test</div> ) },
    with_myui:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageWithUI {...props}/> </React.Suspense> ) },
    generic_url:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageGenericUrl {...props}/> </React.Suspense> ) },
    // panel:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PagePanel {...props}/> </React.Suspense> ) },
    // table:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageTable {...props}/> </React.Suspense> ) },
    // error:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageError {...props}/> </React.Suspense> ) },
    // tab:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageTab {...props}/> </React.Suspense> ) },    
    // dashboard:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <PageDashboard {...props}/> </React.Suspense> ) },
  }
}

export default MyPageTypes;