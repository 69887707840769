import React from 'react'
import MyPageFromConfig from '../MyPageFromConfig';
import HUtils from '../../../helpers/Utils'
import HEncode from '../../../helpers/Encode'


class PageGenericUrl extends React.Component{
    getConfigPage=()=>{
        let configPage = HUtils.Obj.get(this.props, "match.params.configPage");
        if(configPage && typeof(configPage) == "string"){           
            try {
                configPage = decodeURIComponent(configPage);
                return JSON.parse(HEncode.Base64.decode(configPage))
            } catch (error) {
                console.warn("Parse error", error)
            }
        }
        return null;
    }
    render(){
        const configPage = this.getConfigPage();
        if(configPage){
            return <MyPageFromConfig configPage={configPage}/>
        }
        return <div></div>
    }
}
export default PageGenericUrl