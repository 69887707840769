import React from 'react';
import {CButton} from '../MyCore'
import "./styles.css";
import HLink from '../../helpers/Link'
import HUtils from '../../helpers/Utils'
import { HText } from '../../apps/Helpers';
class MyLoadingApp extends React.Component {    
  constructor(props){
    super(props);
    this.state = {
      show: false,
      config: null,
    }
  }
  componentDidMount(){
    // document.getElementsByClassName('ml-app-init')[0].style.display = 'none';
    MyLoadingApp.Helpers.component = this;
  }
  hide=()=>{ this.setState({ show: false, config: null }) }
  show=(opts)=>{ this.setState({ show: true, config: opts }) }
  render(){   
    const {config} = this.state;
    if(config && config.error){
      let _msg = HText.Data.msg_error;
      if(config.error && typeof config.error =="string"){
        _msg = config.error;
      }
      else if(config && config.error && config.error.Msg){
        _msg = config.error.Msg;
      }
      // console.warn("error:",config);
      return (
        <div className="ml-app" style={{flexDirection: 'column'}}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" 
              x="0px" y="0px" width="50px" height="50px" viewBox="0 0 20 16">
              <path fill="#D61F33" d="M10,0L0,16h20L10,0z M11,13.908H9v-2h2V13.908z M9,10.908v-6h2v6H9z"/>
            </svg>
          </div>
          <div style={{padding:'5px',color:'var(--color-danger)'}}>
            {_msg}
          </div>
          <div>
            <CButton color="secondary" variant="" style={{margin:'0px 2px'}} onClick={()=>          
              window.location.reload()
            }>
              <i className="fa fa-refresh"/> {HText.Data.btn_reload}
            </CButton>
            <CButton color="secondary" variant="" style={{margin:'0px 2px'}} onClick={()=>{
              this.hide()
            }}>
              <i className="fa fa-window-close"/> {HText.Data.btn_bypass}
            </CButton>
            <CButton color="secondary" variant="" style={{margin:'0px 2px'}} onClick={()=>{
              HLink.push('/');
              this.hide()
            }}>
              <i className="fa fa-home"/> {HText.Data.btn_homepage}
            </CButton>
          </div>
        </div>
      )
    }
    if(this.state.show){
      let _msg = HUtils.Obj.get(config,"msg","Loading...")
      return (
        <div className="ml-app">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{width:'100px',height:'100px'}}>
              <path fill="#909090" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform 
                  attributeName="transform" 
                  attributeType="XML" 
                  type="rotate"
                  dur="1s" 
                  from="0 50 50"
                  to="360 50 50" 
                  repeatCount="indefinite" />
            </path>
          </svg>
          <div>
            {_msg}
          </div>
        </div>
      ) 
    }
    return (
      <div></div>
    )
  } 
}
MyLoadingApp.Helpers = {
  component: null,
  hide(){
    if(MyLoadingApp.Helpers.component){
      MyLoadingApp.Helpers.component.hide();
    }
  },
  show(opts={}){
    if(opts.notShowLoadingApp==true){return;}
    if(MyLoadingApp.Helpers.component){
      MyLoadingApp.Helpers.component.show(opts);
    }
  },
}

window.MyLoadingApp = MyLoadingApp;
export default MyLoadingApp