import React from 'react'
import HUtils from '../../../helpers/Utils'
class MyUI_HTML extends React.Component {    
  render(){
    const {html,className,style,data,onClick} = this.props;
    // console.warn("MyUI_HTML:",this.props);
    if(data && html && typeof data =="object"){
      let _html = HUtils.SquareBracket.replace(html,data);
      // console.warn("MyUI_HTML:",_html);
      return (
        <div className={className} style={style} onClick={onClick} dangerouslySetInnerHTML={{__html: _html}}/>
      )
    }
    else if(html){
      return (
        <div className={className} style={style} onClick={onClick} dangerouslySetInnerHTML={{__html: html}}/>
      )
    }
    return (
      <div></div>
    )
  } 
}

export default MyUI_HTML