import React, { Component } from 'react';
import { HashRouter, Route, Switch, Router, BrowserRouter } from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import './scss/loading.css';
import './scss/m.css';
import './scss/overide.css';
import './scss/animations.css';
import './scss/fontAwesome/font-awesome.min.css';

import './scss/theme-v1.css';
import './scss/theme-m1.css';

// import './scss/tailwind.min.css';
import {HConfig,HRouter,HLink,HError,HUI,HText} from './apps/Helpers';
import {MyLoadingApp,MyPage,MyModal} from './apps/Components';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  constructor(props){
    super(props);
    let _root = document.getElementById('root');
    if(_root){
      let _theme = HConfig.fnList.getConfig('theme');
      if(_theme){
        _theme.split(" ").map((v,i)=>{_root.classList.add(v);})
      }      
    }
  }
  state={
    errorFound: false
  }
  componentDidCatch(error, info) {
    console.warn("errrror:",error,info);
    this.setState({
      errorFound: true
    });
    HError.errorUI(error,{
      error: error,
      error_stack: error != null ? error.stack : '',
      info: info
    })
    // H.Global.History.refresh();
  }
  render() {
    if(!HConfig.fnList.checkValidConfigApp()){
      return (
        <MyPage configPage={{UIType:'error',Config:'{"msg":"Please contact Admin solve this problem","title":"Missing Config App"}'}}/>
      )
    }
    else if (this.state.errorFound) {
      return (
        <MyPage configPage={{UIType:'error'}}/>
      )
    }
    const TagRouter = HConfig.fnList.getConfig('isUseHash')===false?BrowserRouter:HashRouter;
    return (
      <>
        <ToastContainer position="top-right" autoClose={8000} style={{zIndex: 1999}}/>
        <TagRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {
                HRouter.renderRouter(HConfig.routes)
              }
            </Switch>
          </React.Suspense>
        </TagRouter>
        <MyModal />
        <MyModal type="form"/>
        <MyModal type="msg"/>
        <MyModal type="confirm"/>
        <MyModal type="image_gallery"/>
        <MyModal type="myui2"/>
        <MyModal type="myui3"/>
        <MyLoadingApp />
      </>
    );
  }
}

export default App;
