import React from 'react'
import MyLoadingComponent from '../MyLoading/MyLoadingComponent';

const Form_Default = React.lazy(() => import('./FormTypes/_default'));

const MyFormTypes = {
  addTypes(key,fnUI){
    if(MyFormTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyFormTypes.Types[key]=fnUI;
    }
  },
  Types:{
    _default:(props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}> <Form_Default {...props}/> </React.Suspense> ) },
  }
}

export default MyFormTypes;