import React from 'react'
import MyFormTypes from './MyFormTypes';
class MyForm extends React.Component {    
  _getType=()=>{
    const {config,type} = this.props;
    let _type = '_default';
    if(type){
      // console.warn("_getType 1:",type);
      _type = type.toLowerCase();
    }
    if(config && config.Type){
      // console.warn("_getType 2:",config.Type);
      _type = config.Type.toLowerCase();
    }
    // console.warn("_getType:",_type);
    return _type;//default form
  }
  render(){
    const type = this._getType();
    // console.warn("render MyForm:",type,this.props);
    if(type && MyFormTypes.Types[type]){
      return MyFormTypes.Types[type](this.props);
    }
    return (
      <div></div>
    )
  } 
}

export default MyForm