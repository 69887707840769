const Console = {
  Style:{
    request: 'background: #222; color: #bada55',
    alert: 'background: #ff0000 ; color: #fff',
    screen: 'background: #27ae60 ; color: #fff',
    location: 'background: #855cd0 ; color: #fff',
    constructor: 'background: #2c3e50 ; color: #fff',
    debug_time: 'background: #ffeb3b ; color: #000',
    api: 'background: #009933; color: #fff',
    debug: 'background: #cc48cc; color: #fff',
  }
}

export default Console;