const BuildVersion = {
  buildCode: 4,
  buildDate: '09/03/2021',
  historyBuild: {
    3:{
      note: 'Update nhap kho gia mua'
    },
    2:{
      note: 'Remove footer, AWO-208'
    }
  }
}

window._BuildVersion_ = BuildVersion;
export default BuildVersion;