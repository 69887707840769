import React from 'react'
import MyButtonTypes from './MyButtonTypes';

class MyButton extends React.Component {    
  _getType=()=>{
    const {config,type} = this.props;
    if(type){
      return type.toLowerCase();
    }
    if(config && config.Type){
      return config.Type.toLowerCase();
    }
  }
  render(){
    const type = this._getType();
    if(type && MyButtonTypes.Types[type]){
      return MyButtonTypes.Types[type](this.props);
    }
    return (
      <div></div>
    )
  } 
}

export default MyButton