import Text_Vi from './Language/vi';
import Text_En from './Language/en';
const kLanguage = '_Language_';
const Text = {
  language: 'vi', 
  ListLanguage:{
    default: Text_Vi,
    vi: Text_Vi,
    en: Text_En
  },
  optionsLanguage:[
    {Value:'vi',Text:'Tiếng Việt',Flag:'cifVn'},
    {Value:'en',Text:'English',Flag:'cifGb'},
  ],
  onChangeLanguage(value){
    Text.language = value;
    localStorage.setItem(kLanguage,value);
  },
  initText(HConfig){
    let _defaultLang = HConfig.fnList.getConfig('language');
    let _localLang = localStorage.getItem(kLanguage);
    if(_localLang){
      _defaultLang = _localLang;
    }
    if(_defaultLang){
      Text.language = _defaultLang;
    }
    Text.Data = {...Text.ListLanguage[Text.language]};
  },
  addCustomText(data,language) {
    // console.warn("Text addCustomText:",Text.ListLanguage.vi,data,language);
    Text.ListLanguage[language] = {...Text.ListLanguage[language],...data};
    // console.warn("Test text:",Text.ListLanguage[language],Text.get('btn_register_leave'));
  },
  updateFromServer(data,language){
    let _data = {};
    for (let i in data){
      let _item = data[i];
      _data[_item.Key] = _item.Value;
    }
    Text.ListLanguage[language] = {...Text.ListLanguage[language],..._data};
  },
  Data: {
    btn_cancel:'',
    btn_edit:'',
    btn_change_pw: '',
    btn_close:'',
    btn_confirm:'',
    btn_choosefile:'',
    btn_uploadingfile:'',
    btn_delete:'',
    btn_new:'',
    btn_ok:'',
    btn_save:'',
    btn_saving:'',
    btn_login: '',
    btn_logout: '',
    btn_uploadfile: '',
    btn_refresh_cache: '',
    btn_clear_local_cache: '',
    btn_submit:'',
    btn_tryagain:'',
    btn_bypass:'',
    btn_getstarted:'',
    btn_pickdatefromto: '',
    btn_orderlistreport: '',
    btn_homepage: '',
    btn_reload: '',
    chart_chose_chart:'',
    chart_chose_info:'',
    confirm_create_new:'',
    confirm_delete:'',
    create_new: '',
    msg_confirm_success: '',
    msg_email_send: '',
    msg_input_check_before_send: '',
    msg_input_please: '',
    msg_list_files_onsite: '',
    msg_list_files_plan: '',
    msg_list_files: '',
    msg_loading: '',
    msg_loading_please_waiting: '',
    msg_nodata: '',
    msg_nophoto: '',
    msg_confirm_file: '',
    msg_pw_confirm_wrong: '',
    msg_pw_new_wrong: '',
    msg_pw_enter_new: '',
    msg_pw_input_confirm: '',
    msg_pw_input_new: '',
    msg_error_login_google: '',
    msg_error:'',
    msg_error_get_options:'',
    msg_error_get_list:'',
    msg_error_upload_no_support: '',
    msg_error_upload_onlyimage: '',
    msg_error_data_need_feedback: '',
    msg_success_change_avatar: '',
    msg_success_upload: '',
    msg_success_add: '',
    msg_success_remove: '',
    msg_success_save: '',    
    search:'',
    text_dtc:'',
    text_enter_keyword:'',
    text_groupwpg:'', 
    text_hi:'',
    text_optylead:'',
    text_success: '',
    text_task:'',
    text_welcome:'',
    text_addnew: '',
    text_all: '',
    text_true: '',
    text_false: '',
    text_type_pw:'',
    text_managerfile: '', 
    text_noavatar: '',
    text_changeav: '',
    text_openfolder: '',
    text_inputlinkgd: '',
    text_inputname: '',       
    text_welcome_hello:'',
    tab_myprofile:'',
    tab_changepw:'',
    tab_avatar: '',
    tab_allfiles:'',
    tab_addfilefromlink:'',
  },   
  getText(){
    return Text.ListLanguage[Text.language];
  },
  getTextSendToFirstSetting(){
    let _arr = [];
    let _data = Text.getText();
    if(_data && Object.keys(_data).length>0){
      _arr = Object.keys(_data).map((v,i)=>{
        return {
          Key: v,
          Value: _data[v]
        }
      })
    } 
    return _arr;   
  },  
  get(key){
    if(Text.ListLanguage[Text.language] && Text.ListLanguage[Text.language][key]!=null){
      return Text.ListLanguage[Text.language][key];
    }
    else if(Text.ListLanguage.default[key]!=null){
      return Text.ListLanguage.default[key];
    }
    if(Text.Data[key]){
      return Text.Data[key];
    }
  },
  buildTextForSearch: function(s){
    let _s = Text.removeSpace(s); 
    // console.warn('removeSpace:',_s);
    _s = Text.removeSpecialChars(_s);
    // console.warn('removeSpecialChars:',_s);
    _s = Text.xoaDauInText(_s); 
    // console.warn('xoaDauInText:',_s);
    return _s;
  },
  removeSpecialChars: function(s)
  {      
    if(s!=null){
      s= s + "";//phai chac chan la chuoi
      s = s.replace(/[;-=!@^&\/\\#,+()$~%.'":*?<>{}]/g, '');        
      return s;
    }
    return '';      
  },
  removeSpace: function(s){
    if(s!=null){
      s= s + "";//phai chac chan la chuoi
      return s.replace(/\s/g,'');
    }
    return '';
  },
  xoaDauInText: function(s,{hasSpace, unexpectedRegex}={}){
    let str = s + "";//phai chac chan la chuoi
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    if(hasSpace !==true){
      str = str.replace(/\s/g,'');
    }
    if(unexpectedRegex){
      str = str.replace(unexpectedRegex,'');
    }
    else{
      str = str.replace(/[^A-Za-z0-9!?]/g,'');
    }
  
    str = str.toLowerCase();
    return str;
  },
  compareTextForSearch: function(text, textSearch, opts = {}){
    if(text && textSearch){
      if(opts.searchKoDau){
        let _text = Text.buildTextForSearch(text);
        let _textSearch = Text.buildTextForSearch(textSearch);
        if(new RegExp(_textSearch,"gi").test(_text)==true){
          return true;
        }
      }
      if(new RegExp(textSearch,"gi").test(text)==true){
        return true;
      }
    }    
    return false;
  },
}

window.HText = Text;
export default Text;