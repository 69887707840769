const MyTableFilterHelpers = {
    getKeyOfItem(item){
        return item.key || item.fieldName || "nokey";
    },
    getClassNameOfItem(item,configFilter){
        let _className = "col-12 col-sm-6 col-md-4 col-lg-3";
        if(configFilter && configFilter.classNameItem){
          _className = configFilter.classNameItem;
        }
        let _more = MyTableFilterHelpers.getMoreOfItem(item);
        if(_more && _more.className){
          _className = _more.className;
        }
        return _className;
    },
    getMoreOfItem(item){
        let _more = {};
        if(item){
          if(item._moreObj){
            return item._moreObj;
          }
          if(typeof item.more=="object"){
            return item.more;
          }
          else if(typeof item.more=="string"){
            try {
              let _obj = JSON.parse(item.more);        
              item._moreObj = _obj;
              return _obj;
            } catch (error) {
              console.warn("parse json more error",item.more);
            }
          }
        }    
        return _more;
    },
    buildOptsFilterBootstrapTableInOpts(opts,value){
      opts.filterBootstrapTable = value;
      return opts;
    },
}

export default MyTableFilterHelpers