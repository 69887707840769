import { createStore, combineReducers} from 'redux';

const initialState = {
  isCurrentHomePage: false,
  listDashboard: null,
  openFilter: false,
  evtShowFilter: "evtShowFilter"
}

export const mpos = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'setObjInMPos':
      return {...state, ...rest }
    default:
      return state
  }
}