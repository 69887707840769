import * as M from '../libs/M/M';
import Mv2 from '../libs/M/Mv2'
// import TinyColor from '../libs/TinyColor/TinyColor'
import TinyColor from 'tinycolor2'
// import Lodash from '../libs/Lodash/Core'
import Lodash from 'lodash'
// import Moment from '../libs/Moment';
import Moment from 'moment';
// import ReactAvailableTimes from '../libs/ReactAvailableTimes/AvailableTimes'
// import ReactHorizontalScrollingMenu from '../libs/ReactHorizontalScrollingMenu'
// import ReactMultiCarousel from '../libs/ReactMultiCarousel'
// import * as ReactBootstrapTable from '../libs/ReactBootstrapTable'
import * as MCUI from '../libs/MC/index'
import Numeral from '../libs/NumeralJs/numeral';
export {
  M,
  Mv2,
  TinyColor,
  Lodash,
  MCUI,
  Moment,
  // ReactAvailableTimes,
  // ReactHorizontalScrollingMenu,
  // ReactMultiCarousel,
  // ReactBootstrapTable,
  Numeral
}

window.Moment = Moment;
window.Numeral = Numeral;