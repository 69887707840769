import React from 'react'
import MyLoadingComponent from '../MyLoading/MyLoadingComponent';
import Html from './Types/html';
const ReactImageGallery = React.lazy(() => import('./Types/react_image_gallery'));


const MyUITypes = {
  addTypes(key,fnUI){
    if(MyUITypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyUITypes.Types[key]=fnUI;
    }
  },
  Types:{
    // test:(props)=>{ return ( <div>Test</div> ) },
    html: (props)=>{ return ( <Html {...props}/> ) },
    react_image_gallery: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><ReactImageGallery {...props}/></React.Suspense> ) },    
  }
}

export default MyUITypes;
