import { createStore, combineReducers} from 'redux';
import SLocalStorage from './helpers/LocalStorage';

const initialState = {
  sidebarShow: 'responsive',
  sideMenu: [],
  appMenu: [], 
  titleHeader: 'Home',
  isCurrentHomePage: false
}

export const changeState = (state = initialState, { type, ...rest }) => {
  // console.warn('changeState', state, type, rest)
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'setTitle':
      return {...state, ...rest }
    case 'getMenu':
      const side_menu = SLocalStorage.getObject('SIDE_MENU');
      const app_menu = SLocalStorage.getObject('APP_MENU');
      if(side_menu){
        state.sideMenu = side_menu;
      }
      if(app_menu){
        state.appMenu = app_menu;
      }
      return {...state}
    case 'updateMenu':
      const newState = {...state, ...rest};
      state.sideMenu = newState.sideMenu;
      state.appMenu = newState.appMenu
      return {...state}
    default:
      return state
  }
}
// const store = createStore(changeState)
// const store = createStore(combine)
// const Store = {
//   myStore: null,
//   initStore(Config){
//     Store.myStore = createStore(combineReducers({
//       changeState
//     }));
//   }
// }
export default changeState