import HText from './Text';
const Filter = {
    hasTextInField(item,searchText,opts){
        if(item!=null){
          let _stringSearch = '';
          let _searchText = searchText;
          if(typeof item=="string"){
            _stringSearch = item;
          }
          else{
            try {
              _stringSearch = JSON.stringify(item);//boolean, number, object, array deu duoc
            } catch (error) {
              console.warn("error parse item to search");
            }
          }
    
          let _isNeedBothWord = true;
          if(opts){
            if(opts.trim){
              _stringSearch = _stringSearch.trim();
              _searchText = _searchText.trim();
            }
            if(opts.ignoreCase){
              _stringSearch = _stringSearch.toLowerCase();
              _searchText = _searchText.toLowerCase();
            }
            if(opts.exactly==false){
              _searchText = _searchText.split(/\s+/);
              if(opts.bothWord==false){
                _isNeedBothWord = false;
              }
            }        
          }
    
          if(typeof _searchText=='string'){
            if(_stringSearch.indexOf(_searchText)>-1){
              return true;
            }
          }
          else if(Array.isArray(_searchText)){
            let _count = 0;
            for(let i=0;i<_searchText.length;i++){
              let _rs = Filter.hasTextInField(item,_searchText[i],opts);
              if(_rs==true){    
                if(_isNeedBothWord){
                  _count++; 
                  if(_count==_searchText.length){
                    return true;
                  }
                }
                else{
                  return true;
                }            
              }
            }        
          }      
        }
        return false;
    },
    hasTextInObj(obj,searchText,opts={
        exactly:true,//search nguyen 1 cum tu hay tach tung tu
        bothWord:true,//trong truong hop exactly = false, co can thiet co mat tat ca cac tu trong cum tu hay ko
        trim:true,//co trim word hay ko
        xoadau:true,//co xoa dau tieng viet hay ko
        ignoreCase: true,//Bo qua hoa thuong
        ignoreFields: null,//Mang field bo qua ko search
        onlyFields: null,//Chi search o trong mang field nay
        searchTextKoDau: null,//dung de search ko dau, truyen vao 1 lan de khoi phai convert text nhieu lan
      }){
        // console.warn("hasTextInObj:",opts);        
        let _has = false;
        if(obj){
          obj._SearchPoint = 0;//dung de danh gia diem search
          // if(obj.Id=="11539"){//test
          //   console.warn("hasTextInObj",obj,searchText,opts);
          // }          
          let _textUnsign = opts.searchTextKoDau||HText.xoaDauInText(searchText);
          // console.warn("_textUnsign:",_textUnsign);
          if(obj.hasOwnProperty("Text_Filter")){
            if(obj.Text_Filter && obj.Text_Filter.indexOf(_textUnsign)>-1){
              obj._SearchPoint+=1;
              _has = true;
            }
          }
          let _keys = Object.keys(obj);
          if(_keys && _keys.length>0){
            for(let i=0;i<_keys.length;i++){
              let _k = _keys[i];
              if(opts.ignoreFields){
                if(opts.ignoreFields.indexOf(_k)>-1){
                  continue;
                }
              }
              if(opts.onlyFields){
                if(opts.onlyFields.indexOf(_k)==-1){
                  continue;
                }
              }
              let _item = obj[_k];
              let _rs = Filter.hasTextInField(_item,searchText,opts);
              if(_rs==true){
                obj._SearchPoint+=1;
                return true;
              }
            }
          }      
        }
        return _has;
    }
}
export default Filter