import React from 'react'
import MyTableFilterTypes from "components/MyTableFilter/MyTableFilterTypes";
const ScanBarcode = React.lazy(() =>
  import("../../../../components/MyTableFilter/TypesMobile/scan_barcode")
);

const AppMyTableFilter = {
  init() {
    MyTableFilterTypes.addTypes("scan_barcode", (props) => {
      return <ScanBarcode {...props} />;
    });
  },
};

export default AppMyTableFilter;
