
import React from 'react'
import MyTableCellExt from './_ext';
class MyTableCell_Edit extends React.Component { 
  render() {
    return (
      <div className="mct-col1">Edit</div>
    )
  }
}

export default MyTableCell_Edit