
import React from 'react'
import MyTableCellExt from './_ext';
import MyTableCellHelpers from '../MyTableCellHelpers';
import classnames from 'classnames';

class MyTableCell_Readonly extends MyTableCellExt {
  _getMyClass=()=>{
    return "mct-readonly";
  }    
  renderContent=()=>{
    const {cell} = this.props;
    if(typeof cell=='function'){
      return <div>{cell(this.props)}</div>
    }
    if(typeof cell=='object' && cell!=null ){
      return <div>{"[object]"}</div>
    }
    return cell
  } 
}

export default MyTableCell_Readonly