const MyPageHelpers = {
  parseConfigInConfigPage(configPage){
    let _config = {};
    if(configPage && configPage.Config){
      try {
        _config = JSON.parse(configPage.Config);
      } catch (error) {
        if(typeof configPage.Config=="object"){//neu Config la object
          _config = {..._config,...configPage.Config}
        }
        console.error("parsejsonerr:",configPage);
      }
    }
    if(configPage && configPage.ConfigObj){//Client
      _config = {..._config,...configPage.ConfigObj}
    }
    if(configPage){
      configPage.ConfigObj = _config;
    }    
    return _config;
  }
}

export default MyPageHelpers;