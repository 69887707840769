import React from 'react'
import './style.css';

class Switch extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
    dataOn: 'On',
    dataOff: 'Off',
    disabled: false,
    defaultChecked: null,
    checked: "notset",
    onChange: function(){}
  }
  constructor(props) {
    super(props);
    const {defaultChecked} = this.props;
    this.state = {
      value: defaultChecked
    }
  }

  _onChangeValue=(v)=>{
    const {disabled,onChange} = this.props;
    if(disabled!=true){
      this.setState({value:v},()=>{
        onChange(v)
      })
    }
  }
  render(){
    const {value} = this.state;
    const {dataOn,dataOff,onChange,checked} = this.props;
    // console.warn("render:",value);
    return (
      <label ref={(r)=>{this._cE=r;}} className={`mcui-sw3 mcui-sw3-green mcui-v${value===true?'t':value===false?'f':'n'}`}>
        <span className="mcui-sw3-label" data-on={dataOn} data-off={dataOff}></span>
        <span className="mcui-sw3-handle"></span>
        <div onClick={()=>{this._onChangeValue(false)}} className="mcui-p1"></div>
        <div onClick={()=>{this._onChangeValue(null)}} className="mcui-p2"></div>
        <div onClick={()=>{this._onChangeValue(true)}} className="mcui-p3"></div>
      </label>
    )
  }
}

export default Switch