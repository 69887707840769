import React, { Component } from 'react';
import {createHashHistory, createBrowserHistory } from 'react-router-dom/node_modules/history';
// import {createHashHistory, createBrowserHistory} from "history";//history v5 not working with react-router-dom v5

const Link = {
  history: null,
  isUseHash: true,
  updateHistory(history){
    console.log('updateHistory:',history);
    Link.history = history;
  },
  getInstance({isUseHash}={}){
    if(Link.history==null){
      // Link.history = useHistory();
      if(isUseHash===false){
        Link.isUseHash = false;
        Link.history = createBrowserHistory();
      }
      else{
        Link.history = createHashHistory();
      }
      // Link.history = createBrowserHistory();
    }
    return Link.history;
  },
  goHome(){
    window.location.href = '/';
  },
  goReturnUrl(){
    let _param = Link.getAllUrlParams(window.location.href);
    if (_param!=null){
      let _returnurl = _param.returnurl;
      if (_returnurl!=null){
        Link.push(_returnurl);
      }
    }
  },
  openExternalUrl(url,target='_blank'){
    if(url){
      // console.warn("openExternalUrl:",url);
      if(url.startsWith("/")){
        if(!url.startsWith("/#/")){
          url = "/#" + url;
        } 
      }
      else if(url.startsWith("#/")){
        url = "/" + url;     
      }
      else if(!url.startsWith("http")){
        url = "/#/" + url;
      }    
      window.open(url,target);
    }    
  },
  openSelfUrl(url){//Chi an voi kieu /url
    if(url){
      // console.log("openSelfUrl:",url);
      if(url.startsWith("/")){
        if(url.startsWith("/#/")){
          url = '/' + url.slice(3,url.length);   
        }
      }
      else if(url.startsWith("#/")){
        url = '/' + url.slice(2,url.length);    
      }
      // console.warn("push:",url);
      Link.history.push(url);
      if(Link.isUseHash===false){
        window.location.href = window.location.href;
      }
    }    
  },
  push(path,isNew=false){
    console.log('History push:',path,Link.history);
    if(path!=null&&path.startsWith('http')==true){
      if(isNew==true){
        Link.openExternalUrl(path);
      }
      else{
        Link.openExternalUrl(path,'_self');
      }
    }
    else{
      if(isNew==true){
        Link.openExternalUrl(path);
      }
      else{
        // Link.history.push(path);
        Link.openSelfUrl(path);
      }
    }
    
    // Link.history.push('/#'+path);
    // window.location.href=path;
    // window.location.href = window.location.href;
  },
  refresh(){ 
    console.log(Link.history);
  },
  pushFromHistory(path,history){
    console.log('pushFromHistory push:',path,history);    
    history.push(path);
  },
  getParamsOfURLWithKey(url,key,opts={}){//without hash #
    let _url = url;
    if(opts && opts.removeHash){
      _url = url.replace('#','');
    }
    var _urlObj = new URL(_url);
    var _search_params = _urlObj.searchParams;//Old chrome ko co searchParams
    if(_search_params && _search_params.get){
      return _search_params.get(key);
    }
    return "";
  },
  setParamsOfURLWithKey(url,key,value,opts={}){//without hash #
    let _url = url;
    if(opts && opts.removeHash){
      _url = url.replace('#','-HASH-');
    }
    var _urlObj = new URL(_url);
    var _search_params = _urlObj.searchParams;//Old chrome ko co searchParams
    if(_search_params){
      _search_params.set(key,value);
      if(opts && opts.removeHash){
        let _newURL = _urlObj.toString();
        return _newURL.replace('-HASH-','#');
      }
      else{
        return _urlObj.toString();
      }
    }    
  }, 
  setNewUrlWithoutReload(url){
    if(url){
      window.history.pushState({path:url},'',url);
    }    
  },
  //Hàm lấy param của một url
  getAllUrlParams(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    // we'll store the parameters here
    var obj = {};
    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];
      // split our query string into its component parts
      var arr = queryString.split('&');
      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');
        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
        // (optional) keep case consistent
        // paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') {
          // paramValue = paramValue.toLowerCase();
        }
        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];
          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }
    return obj;
  }
}

export {
  Link
}

// Link.getInstance();
window.HLink = Link;
export default Link;