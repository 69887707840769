import React from 'react'
import {CFormGroup, CInputGroup,CSelect} from '../../MyCore'
import MyTableFilter_Ext from '../Types/_ext'
import HOptions from '../../../helpers/Options'
import MyUI from '../../MyUI/MyUI'

class MyTableFilterItem_With_MyUI extends MyTableFilter_Ext {    
  constructor(props) {
    super(props);
    this.state={
      value: this.getInitValue(''),
    };
  }
  
  _getFilter=(value)=>{
    // console.log('getFilterForBootstrapTable:',this.state);
    const config = this._getConfig();
    if(value!=null){
      let _fieldName = this.getFieldName();
      let _value = value;
      if(_value==''){ return null; }
      let _obj = {};
      _obj[_fieldName] = this.getFilter([_value],{
        type: config.typeDataFilter || "ArrayFilter"
      })
      return _obj;
    }
  }
  _getConfig=()=>{
    if(this._config){
      return this._config;
    }
    this._config = {
      ...this.getConfigShared(),
      typeMyUI: this.getMoreOfKey("typeMyUI",""),
      propsMyUI: this.getMoreOfKey("propsMyUI",{}),
      typeDataFilter: this.getMoreOfKey("typeDataFilter",""),
    }
    return this._config;
  }

  render() {
    const config = this._getConfig();
    const style = this.getMoreOfKey('style') || {};
    let _options = this.getSourceForSelect({});
    // let _ui = HOptions.createOptionsForSelect(_options,config.select.showAll?config.select.textAll:null);
    
    return(
      <CFormGroup>
        <CInputGroup>
          { this.renderPre() }       
          <MyUI type={config.typeMyUI} {...config.propsMyUI} options={_options} onChange={(v)=>{
            // console.warn("onChange:",v)
            this.onChange(v);
          }}/>
          { this.renderClear() }
        </CInputGroup>
      </CFormGroup>
    )
  }
}

export default MyTableFilterItem_With_MyUI