import React from 'react'
import MyUI from '../../MyUI/MyUI'
import PageExt from './_ext';
import {connect} from 'react-redux';

class WithMyUI extends PageExt{
  componentDidMount(){
    this.setTitle()
  }
  render(){
    const {configInConfigPage} = this.props;
    const myUIType = configInConfigPage.type;
    // configInConfigPage.props = { //mat het cac props khac cua configInConfigPage.props
    //   match: this.props.match
    // }
    return(
      <MyUI type="m_content">
          <MyUI {...this.props} type={myUIType} {...configInConfigPage.props}/>
      </MyUI>
    )
  }
}
const mapDispatchToProps=dispatch=>{
  return{
    setTitle: (newTitle)=>dispatch({type: 'setTitle',titleHeader: newTitle}),
  }
}
export default connect(null,mapDispatchToProps)(WithMyUI)
