/*global self*/
/*eslint-disable eqeqeq */
import HDev from './Dev';
import HRoute from './Route';
import HAuth from './Auth';
import HLink from './Link';
import HGlobalSetting from './GlobalSetting';
import SApiAxios from './ApiAxios';
import HUI from './UI';
import HUtils from './Utils';
import {Error as HError} from './Error';
import HLocalStorage from './LocalStorage';
import HText from './Text';
import HStoreRedux from './StoreRedux';
const Config = {
  configBuild: null,
  configApp: {},  
  UIAppConfig: {
    numeralFormat: '0,0.[0]',
    numeralFormatMoney: '0,0.[00]',
    isCheckInvalidProjectId: true,
    ignoreNoProjectId: false,
  },
  UIAppConfigFromServer:{},
  initFromConfigApp(configBuild){
    HLink.getInstance({
      isUseHash: (configBuild&&configBuild.ConfigApp&&configBuild.ConfigApp.isUseHash!=null)?configBuild.ConfigApp.isUseHash:true
    });
    if(configBuild.fnList){
      Config.fnList = Object.assign(Config.fnList,configBuild.fnList);
    }
    if(configBuild && configBuild.ConfigApp){
      Config.configBuild = configBuild;
      if(window._ConfigApp){
        Config.configApp = Object.assign(configBuild.ConfigApp,window._ConfigApp);        
      }
      else{
        Config.configApp = configBuild.ConfigApp;
      } 
      Config.UIAppConfig = Object.assign(Config.UIAppConfig,Config.configApp.UIAppConfig);
            
      HLocalStorage.initLocalStorage(Config);
      HText.initText(Config);
      HDev.initDev(Config);  
      HStoreRedux.initStore(Config,configBuild.CustomStore);                   
      HGlobalSetting.initGlobalSetting(Config); 
      HRoute.initRouter(Config);
      SApiAxios.initApiAxios(Config);
      if(configBuild.fnList && configBuild.fnList.afterInit){
        configBuild.fnList.afterInit(Config);
      }
      HAuth.initAuth(Config);//Can ApiXios, GlobalSetting, LocalStorage truoc                     
    }     
  },
  fnList:{    
    afterInit(Config){},
    afterLogin(){
      console.warn("afterLogin");
      SApiAxios.updateToken(HAuth.getAuthToken());      
      HLink.push('/welcome');
      HUtils.runFnConfig(Config,"afterLogged",[]);
    },
    afterLogged(){},
    afterLogout(){
      console.warn("afterLogout");
      SApiAxios.updateToken(null);
      // HLink.push('/');
      window.location.reload();
    },
    afterLostSession(){
      console.warn("afterLostSession");
      HAuth.logOut();
    },
    afterErrorApi(msg,obj){
      console.warn("afterErrorApi:",msg,obj);
      HError.errorApi(msg,obj);
    },
    getPathHomePageLogged(props, url){
      return url || '/dashboard';
    },
    getPathLogin(props){
      let _pathLogin = `/login`;
      // if(props && props.location && props.location.pathname){
      //   _pathLogin = `/login?redirect=${props.location.pathname}`
      // }
      return _pathLogin;
    },
    getConfig(key){
      // console.warn('getConfig:',key,Config.configApp[key]);
      if(Config){
        if(Config.UIAppConfigFromServer && Config.UIAppConfigFromServer[key]){
          return Config.UIAppConfigFromServer[key];
        }
        if(Config.UIAppConfig && Config.UIAppConfig[key]){
          return Config.UIAppConfig[key];
        }
        else if(Config.configApp && Config.configApp[key]!=null){
          return Config.configApp[key]
        }
      }      
    },
    getIsDevMode(){
      return HDev.isDebug;
    },
    getAuthName(){
      return HAuth.getName();
    },
    getAuthImg(){
      return HAuth.getImgUrl();
    },
    getAuthId(){
      return HAuth.getUserId()
    },
    updateAuthImg(url){
      return HAuth.updateImgUrl(url);
    },
    getStoreRedux(){
      return HStoreRedux.myStore;
    },
    getAppLogo(){
      return HAuth.getAppLogo();
    },
    checkValidConfigApp(){
      let _valid = false;
      if(Config.fnList.getIsDevMode()==true || window._ConfigApp!=null){
        _valid = true;
      }
      return _valid;
    },
    updateRouteServer(listRouteServer){
      HRoute.listRoutesServer = listRouteServer;
    },
    updateUIAppConfig(obj){
      if(Config && Config.UIAppConfigFromServer && obj){
        Config.UIAppConfigFromServer = Object.assign(Config.UIAppConfigFromServer,obj);
      }
    },
    showError(msg){
      HUI.Toast.showError(msg);
    },
  },
}

export default Config;