import SApiAxios from './ApiAxios';
import HLocalStorage from './LocalStorage';
import HUtils from './Utils';
import HStoreRedux from './StoreRedux';
import HText from './Text';

const GlobalSetting = {
  HConfig: null,
  kCategory: 'CATEGORY_LIST',
  kFirstSetting: 'FIRST_SETTING',
  kAppMenu: 'APP_MENU',
  kSideMenu: 'SIDE_MENU',
  dataAppMenu: null,
  dataFirstSetting: null,
  dataCategory: null,
  dataSideMenu: null,
  initGlobalSetting(HCofig){
    GlobalSetting.HConfig = HCofig;    
    GlobalSetting.dataCategory = HLocalStorage.getObject(GlobalSetting.kCategory,[]);
    GlobalSetting.dataFirstSetting = HLocalStorage.getObject(GlobalSetting.kFirstSetting,[]);
    GlobalSetting.parseFirstSetting(GlobalSetting.dataFirstSetting,{fromLocal:true});
    GlobalSetting.loadDataCategory(GlobalSetting.dataCategory);
  },
  parseFirstSetting(data,{fromLocal}={}){
    console.warn("parseFirstSetting:",data);    
    if(data.TextDisplaySettings && fromLocal!==true){//ko update text tu localStorage
      HText.updateFromServer(data.TextDisplaySettings,HText.language);
    }
    if(data.UIAppConfig && GlobalSetting.HConfig){
      GlobalSetting.HConfig.UIAppConfigFromServer = data.UIAppConfig;
    }
  },
  loadDataCategory(dataCategory){
    let _dataCategoryExt = dataCategory;
    if(dataCategory && dataCategory.length>0 && GlobalSetting.HConfig && GlobalSetting.HConfig.fnList){
      let _isMobileWeb = GlobalSetting.HConfig.fnList.getConfig('isMobileWeb');
      if(_isMobileWeb){
        let _mw = dataCategory.filter((v,i)=>{      
          if(v.Config && typeof v.Config=="string" && v.Config.indexOf(`"mw":`)>-1){
            return true;  
          }        
          return false;
        }).map((v,i)=>{
          let _config = {};
          try {
            let _configObj = JSON.parse(v.Config);
            if(_configObj.mw){
              _config = _configObj.mw;
            }
          } catch (error) {
            console.warn("parse json mw error:",error);
          }
          return {
            ...v,
            UIUrl: `/m${v.UIUrl}`,
            ..._config
          }
        })
        if(_mw && _mw.length>0){
          // _dataCategoryExt = [...dataCategory,..._mw]//ko dung man hinh desktop
          _dataCategoryExt = _mw;
        }
      }      
    }
    HUtils.runFnConfig(GlobalSetting.HConfig,"updateRouteServer",[_dataCategoryExt]);
  },
  requestFirst({requestData}={}){
    let _requestData = {
      ...requestData
    }
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: 'GlobalAppSetting',
        name: 'FisrtSetting'
      },
      data:_requestData,
      successCallBack:(response)=>{
        if(response.Data!=null){
          GlobalSetting.dataFirstSetting = response.Data;
          GlobalSetting.parseFirstSetting(GlobalSetting.dataFirstSetting);
          HLocalStorage.setObject(GlobalSetting.kFirstSetting,response.Data);
        }
      },
      errorCallBack:(error,response)=>{}
    })
  },
  requestConfigMenu(){
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: 'ConfigMenu',
        name: 'CategoryScreenList'
      },
      data:{        
      },
      successCallBack:(response)=>{
        if(response.Data){
          GlobalSetting.dataCategory = response.Data;
          console.warn("CategoryScreenList:",response.Data);
          GlobalSetting.loadDataCategory(GlobalSetting.dataCategory);         
          HLocalStorage.setObject(GlobalSetting.kCategory,response.Data);
        }
      },
      errorCallBack:(error,response)=>{}
    })
  },
  requestProjectScreenHeader(){
    SApiAxios.generic({
      request:{
        method: 'POST',
        url: '/api/v1/ProjectScreenHeader/ProjectScreens'
      },
      data:{},
      isHideMsgError: true,
      successCallBack:(response)=>{
        if(response && response.Data){
          if(response.Data.side_menu){
            GlobalSetting.dataSideMenu = JSON.parse(response.Data.side_menu);
            HLocalStorage.setObject(GlobalSetting.kSideMenu,JSON.parse(response.Data.side_menu));
          }
          if(response.Data.app_menu){
            GlobalSetting.dataAppMenu = JSON.parse(response.Data.app_menu);
            HLocalStorage.setObject(GlobalSetting.kAppMenu,JSON.parse(response.Data.app_menu));
          }
          // store.dispatch({type: 'updateMenu',sideMenu: JSON.parse(response.Data.side_menu), appMenu: JSON.parse(response.Data.app_menu)})
          HStoreRedux.dispatch({type: 'updateMenu',sideMenu: JSON.parse(response.Data.side_menu), appMenu: JSON.parse(response.Data.app_menu)});
        }
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
      }
    })
  }
}
export default GlobalSetting;