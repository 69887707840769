import React from 'react'

const MyLayoutTypes = {
  addTypes(key,fnUI){
    if(MyLayoutTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyLayoutTypes.Types[key]=fnUI;
    }
  },
  Types:{
     
  }
}

export default MyLayoutTypes;
