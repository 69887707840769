import React from "react";
import * as Routers from "./_routes";
// import Framework7 from 'framework7/framework7.esm.bundle.js';
// import Framework7React from 'framework7-react';
// import 'framework7/css/framework7.bundle.min.css'
import store from "../../store";
import {
  HGlobalSetting,
  HLink,
  HUtils,
  HAuth,
  HConfig,
  HStoreRedux,
} from "../Helpers";
import { SApiAxios } from "../Services";
import * as CustomStore from "./_store";

import AppMyPage from "./components/MyPage";
import AppMyUI from "./components/MyUI";
import AppMyChart from "./components/MyChart";
import AppMyTableCell from "./components/MyTableCell";
import AppMyLayout from "./components/MyLayout";
import AppMyTableFilter from "./components/MyTableFilter";
import MyUITypesInit from "../../components/MyUI/MyUITypesInit";
import BuildVersion from "./_version";
const ConfigBuild = {
  Routers: Routers,
  CustomStore,
  ConfigApp: {
    defaultHomePage: "",
    theme: "theme-m1 theme-ajuma",
    BaseUrl: "https://posapi.allianceitsc.com",
    // BaseUrl: "https://api.ajumagarden.com",
    listDomainDebug: ["mpos.allianceitsc.com"], //Check debug
    defaultShowSidebar: false,
    footer: "<span>&copy; 2020 Alliance. </span>",
    dateFormat: "DD-MMMYY",
    dateFormatJQ: "dd-My",
    dateFormatRDP: "dd-MMMYY",
    isMobileWeb: true,
    appName: "mapp-ajumagarden",
    localName: "_awm_store_screens",
    reloadWhenChangeQuerySearchInHash: true,
    title: "Ajuma Garden",
    employeeFooter:
      '<div><div style="font-weight: bold;">Công ty TNHH RICA</div><div style="font-weight: bold;">Ajuma Garden</div><div>31-33 Nguyễn Văn Đậu, P.6, Bình Thạnh, TP.HCM.</div><div><a href="tel: 0978487735">0978487735</a></div></div>',
  },
  fnList: {
    afterInit(HConfig) {
      MyUITypesInit.init();
      AppMyTableFilter.init();
      // Framework7.use(Framework7React);
      // store.dispatch({type: 'set', sidebarShow: HConfig.fnList.getConfig('defaultShowSidebar')});
      HStoreRedux.dispatch({
        type: "set",
        sidebarShow: HConfig.fnList.getConfig("defaultShowSidebar"),
      });
      if (HConfig.configApp && HConfig.configApp.hasRequestFirst !== false) {
        HGlobalSetting.requestFirst();
      }

      /**Add meta */
      var meta = document.createElement("meta");
      meta.name = "apple-mobile-web-app-capable";
      meta.content = "yes";
      document.head.appendChild(meta);

      var metaName = document.createElement("meta");
      metaName.name = "apple-mobile-web-app-title";
      metaName.content = "MPos";
      document.head.appendChild(metaName);

      var meta2Old = document.querySelector('meta[name="viewport"]');
      if (meta2Old) {
        meta2Old.remove();
      }
      var meta2 = document.createElement("meta");
      meta2.name = "viewport";
      meta2.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      document.head.appendChild(meta2);

      var link = document.createElement("link");
      link.rel = "apple-touch-icon";
      link.sizes = "128x128";
      link.href = "./config/icon.png";
      document.head.appendChild(link);
      //<link rel="apple-touch-icon" sizes="128x128" href="niceicon.png">

      /** Check history, show back */
      if (HLink.history) {
        HLink.history.listen((location, action) => {
          console.log(
            `The current URL is ${location.pathname} - ${location.search} - ${location.hash}`
          );
          console.log(`The last navigation action was ${action}`);
          if (
            location &&
            location.pathname &&
            location.pathname.indexOf("mhome") > -1
          ) {
            HStoreRedux.dispatch({
              type: "setObjInMPos",
              isCurrentHomePage: true,
            });
          } else {
            HStoreRedux.dispatch({
              type: "setObjInMPos",
              isCurrentHomePage: false,
            });
          }
        });
      }
      if (window.location.href.indexOf("mhome") > -1) {
        HStoreRedux.dispatch({ type: "setObjInMPos", isCurrentHomePage: true });
      }
    },
    afterLogged() {
      HGlobalSetting.requestConfigMenu();
      HGlobalSetting.requestProjectScreenHeader();
    },
    afterLogin() {
      console.warn("afterLogin");
      SApiAxios.updateToken(HAuth.getAuthToken());
      HLink.push("/mhome");
      HUtils.runFnConfig(HConfig, "afterLogged", []);
    },
  },
};

export default ConfigBuild;
