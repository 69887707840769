import {M,TinyColor,Lodash,Mv2} from '../apps/Libs';

const Color = {
  borderHeaderProject: '#b6b7a6',
  bgPjHeader: '#fffecf',
  bgExpand: '#E4E5E6',
  bgExpand2: '#E4E5E6',
  icExpandUp: '#f44336',
  icExpandDown: '#44cfae',
  risk1: '#ec5463',
  risk3: '#fdcd55',
  risk5: '#9ed269',
  listColorTextOfBG:{},
  arrColorButtons: ["danger","success","secondary","warning"],
  validButtonsColor(color){
    if(color){
      if(Color.arrColorButtons.indexOf(color)>-1){
        return true;
      }
    }
    return false;
  },
  getDarken(color){
    let _c = TinyColor(color).darken().toString();
    return _c;
  },
  getDarken7(color){
    let _c = TinyColor(color).darken(7).toString();
    return _c;
  },
  getDarken15(color){
    let _c = TinyColor(color).darken(30).toString();
    return _c;
  },
  getTextColorBG(color,df){
    if(color){
      if(Color.listColorTextOfBG[color]!=null){
        return Color.listColorTextOfBG[color];
      }
      else{
        let _color = new Mv2.Color(color);
        Color.listColorTextOfBG[color] = _color.getTextWB();
        return Color.listColorTextOfBG[color];
      }
    }
    return df || '#000000';
  },
  getDarkColorBG(color){
    if(color){
      if(Color.listDarkColor[color]!=null){
        return Color.listDarkColor[color];
      }
      else{
        let _color = Color.getDarken7(color);
        Color.listDarkColor[color] = _color;
        return Color.listDarkColor[color];
      }
    }
    return '#ffffff';
  },
  getDarkColor15BG(color){
    if(color){
      if(Color.listDarkColor[color]!=null){
        return Color.listDarkColor[color];
      }
      else{
        let _color = Color.getDarken15(color);
        Color.listDarkColor[color] = _color;
        return Color.listDarkColor[color];
      }
    }
    return '#ffffff';
  },
  getTextWB(color){
    let _c = TinyColor(color);
    if(_c && _c.isDark()){
      return '#ffffff';
    }
    return '#000000';
  },
}

export default Color;