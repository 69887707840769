import React from 'react'
import MyLoadingComponent from '../MyLoading/MyLoadingComponent';
import MyUITypes from './MyUITypes'
import Div from './Types/div';
import Iframe from './Types/iframe';

const Table = React.lazy(() => import('./Types/table'));
const PageHeader = React.lazy(() => import('./Types/page_header'));
const PageFilter = React.lazy(() => import('./Types/page_filter'));
const Box = React.lazy(() => import('./Types/box'));
const Html = React.lazy(() => import('./Types/html'));
const MyUI_from_string = React.lazy(() => import('./Types/myui_from_string'));
const InputText = React.lazy(() => import('./Types/input_text'));
const InputTextWithLabel = React.lazy(() => import('./Types/input_text_with_label'));
const InputTextarea = React.lazy(() => import('./Types/input_textarea'));
const InputTextareafull = React.lazy(() => import('./Types/input_textareafull'));
const InputCheck = React.lazy(() => import('./Types/input_check'));
const InputRadio = React.lazy(() => import('./Types/input_radio'));
const InputSelect = React.lazy(() => import('./Types/input_select'));
const MyButton = React.lazy(() => import('./Types/my_button'));
const JQDatePicker = React.lazy(() => import('./Types/jq_datepicker'));
const JQDateTimePicker = React.lazy(() => import('./Types/jq_datetimepicker'));
const EditConfig = React.lazy(() => import('./Types/edit_config'));

const Select2 = React.lazy(() => import('./Types/select2'));
const Select2Color = React.lazy(() => import('./Types/select2color'));
const Popover = React.lazy(() => import('./Types/popover'));
const Tooltip = React.lazy(() => import('./Types/tooltip'));
const Form = React.lazy(() => import('./Types/form'));
const TableExpand = React.lazy(() => import('./Types/table_expand'));
const Select2Box = React.lazy(() => import('./Types/select2box'));
const Tab = React.lazy(() => import('./Types/tab'));
const ArrayTags = React.lazy(() => import('./Types/array_tags'));
const FilesManager = React.lazy(() => import('./Types/files_manager'));
const Nodata = React.lazy(() => import('./Types/nodata'));
const State = React.lazy(() => import('./Types/state'));
const State_loading = React.lazy(() => import('./Types/state_loading'));
const State_empty = React.lazy(() => import('./Types/state_empty'));
const State_error = React.lazy(() => import('./Types/state_error'));
const LoadingIcon = React.lazy(() => import('./Types/loading_icon'));
const CommonLogin = React.lazy(() => import('./Types/common_login'));
const CommonForgotPassword = React.lazy(() => import('./Types/common_forgot_password'));
const CommonResetPassword = React.lazy(() => import('./Types/common_reset_password'));
const DevListTypes = React.lazy(() => import('./TypesDev/dev_list_types'));

const MyUITypesInit = {
  list:{
    div:(props)=>{ return ( <Div {...props}/> ) },
    iframe:(props)=>{ return ( <Iframe {...props}/> ) },
    table: (props)=>{ return ( <Table {...props}/> ) },
    page_header: (props)=>{ return ( <PageHeader {...props}/> ) },
    page_filter: (props)=>{ return ( <PageFilter {...props}/> ) },
    box: (props)=>{ return ( <Box {...props}/> ) },    
    myui_from_string: (props)=>{ return ( <MyUI_from_string {...props}/> ) },
    input_text: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><InputText {...props}/></React.Suspense> ) },
    input_textarea: (props) =>{return ( <InputTextarea {...props}/> )},
    input_check: (props) =>{return (  <React.Suspense fallback={<MyLoadingComponent />}><InputCheck {...props}/></React.Suspense> )},
    input_radio: (props) =>{return (  <React.Suspense fallback={<MyLoadingComponent />}><InputRadio {...props}/></React.Suspense> )},
    input_select: (props) =>{return ( <InputSelect {...props}/> )},
    input_textareafull: (props) =>{return ( <InputTextareafull {...props}/> )},
    my_button: (props)=>{ return ( <MyButton {...props}/> ) },    
    jq_datepicker: (props)=>{ return ( <JQDatePicker {...props}/> ) },
    jq_datetimepicker: (props)=>{ return ( <JQDateTimePicker {...props}/> ) },
    edit_config: (props)=>{ return ( <EditConfig {...props}/> ) },
    select2: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><Select2 {...props}/></React.Suspense> ) },
    select2color: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><Select2Color {...props}/></React.Suspense> ) },
    popover: (props)=>{ return ( <Popover {...props}/> ) },
    tooltip: (props)=>{ return ( <Tooltip {...props}/> ) },
    form: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><Form {...props}/></React.Suspense> ) },
    table_expand: (props)=>{ return ( <TableExpand {...props}/> ) },
    select2box: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><Select2Box {...props}/></React.Suspense> ) },
    tab: (props)=>{ return ( <Tab {...props}/> ) },
    array_tags: (props)=>{ return ( <ArrayTags {...props}/> ) },
    files_manager: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><FilesManager {...props}/></React.Suspense> ) },
    nodata: (props)=>{ return (<Nodata {...props}/>)},
    state: (props)=>{ return ( <React.Suspense fallback={<MyLoadingComponent />}><State {...props}/></React.Suspense> ) },
    state_loading: (props)=>{ return (<State_loading {...props}/>)},
    state_empty: (props)=>{ return (<State_empty {...props}/>)},
    state_error: (props)=>{ return (<State_error {...props}/>)},
    loading_icon: (props)=>{ return (<LoadingIcon {...props}/>)},
    input_text_with_label: (props)=>{ return (<InputTextWithLabel {...props}/>)},
    common_login:  (props)=>{ return (<CommonLogin {...props}/>)},
    common_forgot_password:  (props)=>{ return (<CommonForgotPassword {...props}/>)},
    common_reset_password:  (props)=>{ return (<CommonResetPassword {...props}/>)},
    dev_list_types:  (props)=>{ return (<DevListTypes {...props}/>)},
  },
  init:()=>{
    let _keys = Object.keys(MyUITypesInit.list);
    for(let k of _keys){
      MyUITypes.addTypes(k,MyUITypesInit.list[k]);
    }
  }
}

export default MyUITypesInit;