'use strict';

import React, { Component } from 'react';
import classnames from 'classnames';
import PaginationBoxView from './PaginationBoxView';
import HLocalStorage from '../../helpers/LocalStorage';

export default class PaginationWithData extends Component {
  static defaultProps = {
    sizePerPage: 50
  }

  constructor(props) {
    super(props);
    this.state = {
      dataPagination: [],      
      pageCount: 50,
      currentPage: 0,
      forcePage: undefined,
    }
    this._parseLastPage();
  }

  componentDidMount() {    
    this._calc(this.props.data);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data !== this.props.data){
      // console.warn("componentWillReceiveProps:",nextProps.data);
      this._calc(nextProps.data, {forcePage: nextProps.forcePage});
    }
  }

  _parseLastPage=()=>{
    const {screenCode,isRememberLastPage} = this.props;
    if(screenCode && isRememberLastPage){
      this._lastSaved = HLocalStorage.ConfigScreen.getCustomField({
        screenCode: screenCode,
        key: 'lastpage'
      });
      if(this._lastSaved && this._lastSaved.page>0){
        this.state.forcePage = this._lastSaved.page;
      }
      // console.warn("lastSaved",this._lastSaved);
    }
  }

  _saveLastPage=({page}={})=>{
    const {screenCode,isRememberLastPage} = this.props;
    if(screenCode && isRememberLastPage){
      HLocalStorage.ConfigScreen.setCustomField({
        screenCode: screenCode,
        key: 'lastpage',
        data: {
          page: page
        }
      });
      // console.warn("_saveLastPage",page);
    }    
  }

  _paginate=(array, page_number)=> {
    const {sizePerPage} = this.props;
    if(array && array.length>0){
      return array.slice((page_number - 1) * sizePerPage, page_number * sizePerPage);
    }
    return [];
  }
  _calc=(data,{forcePage}={})=>{
    const {sizePerPage} = this.props;
    let _forcePage = 1;
    if(forcePage){
      _forcePage = forcePage
    }
    else if(this._lastSaved){
      _forcePage = this._lastSaved.page;
    }

    let _pageCount = 1;
    let _dataPagination = this._paginate(data,_forcePage);
    if(data!=null && sizePerPage>0){
      _pageCount = Math.ceil(data.length / sizePerPage);      
    }
    // console.warn("calc",sizePerPage,_dataPagination,data);    
    this.setState({
      pageCount: _pageCount,
      dataPagination: _dataPagination,
      forcePage: _forcePage,
    },()=>{
      if(this._lastSaved){
        this._lastSaved = null;//clear
      }
      else{
        this._saveLastPage({
          page: 1
        });
      }      
    })
  }
  _calcChangePage=(currentPage)=>{
    const {data,screenCode,isRememberLastPage, onPageChange} = this.props;
    if(data && data.length>0){
      let _dataPagination = this._paginate(data,currentPage);
      this.setState({
        dataPagination: _dataPagination,
        currentPage: currentPage,
        forcePage: undefined
      },()=>{
        this._saveLastPage({
          page: currentPage
        }); 
        onPageChange && onPageChange(currentPage)
      })
    }    
  }

  render() {
    const {data,containerClassName,subContainerClassName,activeClassName,controlPageFromApi,style,className,hideWhenOnePage} = this.props;
    const {dataPagination,pageCount,forcePage} = this.state;
    let _content = null;
    if(this.props.children && typeof this.props.children==="function"){
      _content = this.props.children({
        dataPagination: dataPagination
      })
    }
    let _forcePage = forcePage;
    let _pageCount = pageCount;
    let _onChangePage = this._calcChangePage;
    if(controlPageFromApi && controlPageFromApi.isControlPageFromApi){
      _pageCount = controlPageFromApi.pageCount||0;   
      _forcePage = controlPageFromApi.page;
      _onChangePage = controlPageFromApi.onPageChange;           
    }
    // console.warn("pageCount",pageCount,dataPagination,forcePage);
    return (
      <div style={style} className={className}> 
        { _content }    
        {
          data && data.length>0 && !(hideWhenOnePage===true && _pageCount==1) &&
          <PaginationBoxView
            forcePage={_forcePage}
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={_pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={({ selected })=>{
              // console.warn("selected: ",selected);
              _onChangePage(selected+1);              
            }}
            containerClassName={classnames('pagination',containerClassName)}
            subContainerClassName={classnames('pages pagination',subContainerClassName)}
            activeClassName={classnames('active',activeClassName)}
          />
        }        
      </div>
    )
  }
}