
import React from 'react'
import MyTableCellExt from './_ext';
import MyUI from '../../MyUI/MyUI';
import {CButton} from '../../MyCore';
import HText from '../../../helpers/Text'
import HUI from '../../../helpers/UI';

class MyTableCell_Addable extends React.Component { 
    _renderDefault=()=>{
       return (
        <div>
            <div>Nhập tên:</div>
            <MyUI 
                ref={r=>{this._cInputNew=r;}}
                type="input_text" 
            />
        </div>
       )
    }

    _renderForm=()=>{
        const {configAddable,fnList} = this.props;
        if(configAddable && configAddable.form){
          return (
              <div>Form</div>
            // <MyForm 
            //   ref={r=>{this._cForm=r;}} 
            //   getOptionOfKey={(key)=>{    
            //     if(fnList && fnList.fnGetOptions){
            //       let _options = fnList.fnGetOptions();
            //       if(_options[key]){
            //         return _options[key];
            //       }
            //     }                
            //     return [];
            //   }}
            //   config={configAddable.form}/>
          )
        }
        else{
          return this._renderDefault();
        }
    }

    _onSubmit=(hideCallback)=>{
        const {configAddable,onSubmit,fName} = this.props;
        if(configAddable.form){
          hideCallback && hideCallback();    
          if(this._cForm){
            let _values = this._cForm.getValues();
            onSubmit && onSubmit(_values,"");
          }  
        }
        else{
          if(this._cInputNew!=null){
            let _newValue = this._cInputNew.getValue();
            if(_newValue==null||_newValue.length==0){
              HUI.Toast.showWarn('Vui lòng nhập tên trước khi đồng ý thêm mới!');
            }
            else{
              hideCallback && hideCallback();
              let _data = {};
              _data[fName||"Name"] = _newValue;     
              onSubmit && onSubmit(_data,_newValue);
            }
          }
        }
    }
    render() {
        const {hidden} = this.props;
        const _titlePopover = this.props.title || HText.get('text_addnew');

        return (
        <div className="mct-addable" hidden={hidden}>
            <MyUI 
                type="popover"
                placement="left"
                title={_titlePopover}
                overlay={this._renderForm()}
                showButton={true}
                onOK={()=>console.warn('aaa')}
            >
                <button><i className="fa fa-plus-circle"/></button>
                {/* <CButton><i className="fa fa-plus-circle"/></CButton> */}
            </MyUI>
        </div>
        )
    }
}

export default MyTableCell_Addable