import React, { Component } from 'react';
import HFilter from '../../helpers/Filter';
import HText from '../../helpers/Text';

class MyTableFilterData extends Component {
  constructor(props) {
    super(props);
    this._optsSearch = {
      exactly:true,//search nguyen 1 cum tu hay tach tung tu
      bothWord:true,//trong truong hop exactly = false, co can thiet co mat tat ca cac tu trong cum tu hay ko
      trim:true,//co trim word hay ko
      xoadau:true,//co xoa dau tieng viet hay ko
      ignoreCase: true,//Bo qua hoa thuong
      ignoreFields: null,//Mang field bo qua ko search
      onlyFields: null,//Chi search o trong mang field nay
    }
  }

  componentDidMount(){
  }

  _onSearchText=(searchText,dataList)=>{
    const {configFilter} = this.props;
    if(configFilter && configFilter.optsSearch){
      this._optsSearch = Object.assign(this._optsSearch,configFilter.optsSearch);
    }
    let _arrFiltered = null;
    let _dataList = dataList || this.state.dataList;
    if (searchText && _dataList){
      let _searchTextKoDau = HText.buildTextForSearch(searchText);
      let _optsSearch = {...this._optsSearch,searchTextKoDau: _searchTextKoDau}
      _arrFiltered = _dataList.filter((v,i)=>{
        return HFilter.hasTextInObj(v,searchText,_optsSearch);
      });
    }
    if(dataList){
      return _arrFiltered;
    }
  }
  _onFilterData=(filter,dataList)=>{
    if(filter && dataList){
      let _dataList = dataList;
      let _arrFiltered = _dataList || [];
      if (filter && Object.keys(filter).length>0 && _dataList){
        let _keysFilter = Object.keys(filter);
        for (let l=0;l<_keysFilter.length;l++){
          let _fieldName = _keysFilter[l];
          let _filter = filter[_fieldName];              
          if (_filter.value && _filter.isServer!==true && _dataList){      
            let _filterValue = _filter.value;    
            if(_filter.type=='ArrayFilter'){            
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  let _s = v[_fieldName];
                  for (let k=0;k<_filterValue.length;k++){
                    if (_s == _filterValue[k]){
                      return true;
                    }
                  }
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }
            else if(_filter.type=='TextFilter'){
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  return HFilter.hasTextInObj({value:v},_filterValue);               
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }   
            else if(_filter.type=='SelectFilter'){
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  let _s = v[_fieldName];
                  if(_s == _filterValue){
                    return true;
                  }                
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }
            else if(typeof _filter.value=='object'){
              if(_filter.value.type=='CustomFilter'){
                if(_filter.value.start && _filter.value.end){
                  // console.warn("filter CustomFilter:",_filter);
                  _arrFiltered = _arrFiltered.filter((v,i)=>{
                    if (v){
                      let _s = v[_fieldName];
                      // console.warn("check:",_s,_s>= _filter.value.start,_s<=_filter.value.end);
                      if(_s>= _filter.value.start && _s<=_filter.value.end){
                        return true;
                      }                
                    }
                    if (!v.hasOwnProperty(_fieldName)){
                      return true;
                    }
                    return false;
                  });
                }              
              }
            }       
          }
        }
      }
      else{
        _arrFiltered = _dataList;
      }
      if(filter._SearchText && filter._SearchText.value && filter._SearchText.isServer!==true){
        _arrFiltered = this._onSearchText(filter._SearchText.value,_arrFiltered);
      }
      return _arrFiltered;
    }
    else{
      return dataList;
    }    
  }
  _compareValues=(key)=>{
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return comparison;
    };
  }
  _sortData=(arr)=>{
    let _arr = arr
    const {onSort} = this.props;
    if(onSort && onSort.fieldName && onSort.orderBy){
      if(onSort.orderBy == 'asc'){
        _arr.sort(this._compareValues(onSort.fieldName))
      }
      else{
        _arr.sort(this._compareValues(onSort.fieldName))
        _arr.reverse(this._compareValues(onSort.fieldName))
      }
    }
    return _arr
  }

  render() {
    const {data,currentFilter, getDataFilter, onSort} = this.props;
    // console.warn("render MyTableFilterData",onSort,data);
    let _content = null;
    let _dataFiltered = this._onFilterData(currentFilter,data);
    if(getDataFilter){
      getDataFilter(_dataFiltered)
    }
    if(onSort){
      this._sortData(_dataFiltered)
    }
    if(this.props.children && typeof this.props.children==="function"){
      _content = this.props.children({
        dataFiltered: _dataFiltered
      })
    }
    return _content;
  }
}

export default MyTableFilterData;