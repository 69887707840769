import React from 'react'

class MyUI_Div extends React.Component {    
  render(){
    return (
      <div>MyUI_Div</div>
    )
  } 
}

export default MyUI_Div