/*eslint-disable eqeqeq */

import React from 'react';
import HConfig from '../../helpers/Config';
import HUtils from '../../helpers/Utils';
import HLink from '../../helpers/Link';
import HColor from '../../helpers/Color';
import HOptions from '../../helpers/Options';
import classnames from 'classnames';

const MyTableCellHelpers = {
  getKeyOfCell({cell,row,extra}={}){
    if(row && extra && row._UI_NeedUpdate){
      let _fieldName = extra.fieldName;
      if(_fieldName && row._UI_NeedUpdate.hasOwnProperty(_fieldName)){        
        delete row._UI_NeedUpdate[_fieldName];
        return new Date().getTime();
      }
    }
  },
  getClassOfCell(cCell){
    const {cell,row,extra} = cCell.props;
    const fieldName = MyTableCellHelpers.getFieldNameOfCell(cCell);
    let _myClass = "";
    if(row){
      if(row.UI_StyleMore && row.UI_StyleMore[fieldName]){
        _myClass = row.UI_StyleMore[fieldName].class;
      }
    }
    if(cCell._getMyClass){
      _myClass = classnames(cCell._getMyClass(),_myClass)
    }
    return _myClass;
  },
  getStyleOfCell(cCell){
    const {cell,row,extra} = cCell.props;
    const {more,fieldName} = cCell.getSharedObj();
    let _styles = {};
    if(row){
      if(row.UI_StyleRow){
        _styles = Object.assign(_styles,row.UI_StyleRow);
      } 
      // console.warn("getStyleOfCell: ",fieldName);
      if(row.UI_StyleCell && row.UI_StyleCell[fieldName]){
        _styles = Object.assign(_styles,row.UI_StyleCell[fieldName]);
      }
      if(row.UI_StyleMore && row.UI_StyleMore[fieldName]){
        _styles = Object.assign(_styles,row.UI_StyleMore[fieldName]);
      }
    }
    if(more && more.style){
      _styles = Object.assign(_styles,more.style);
    }

    if(extra && extra.Type!=null && row!=null){
      let _bgF = extra.Type.bgF;
      if(_bgF!=null){
        if(row[_bgF]!=null){
          let _color = HColor.getTextWB(row[_bgF]);
          _styles = Object.assign(_styles,{background:row[_bgF],color:_color});
        }
        else if(_bgF.startsWith('#') || _bgF.startsWith('linear-gradient')){
          let _color = HColor.getTextWB(_bgF);
          _styles = Object.assign(_styles,{background:_bgF,color:_color});
        }
      }        
    }
    return _styles;
  },
  getFieldNameOfCell(cCell){
    const {cell,row,extra} = cCell.props;
    if(extra && extra.fieldName){
      return extra.fieldName;
    }
  },
  getExtraColumn(cCell,fieldName){
    const {cell,row,extra} = cCell.props;
    if(extra && extra.extraData && extra.extraData.Columns){
      return extra.extraData.Columns[fieldName];
    }
  },
  getCommonProps(cCell,{customFieldName}={}){
    const {cell,row,extra} = cCell.props;
    const sharedObj = (cCell && cCell.getSharedObj())||{};
    let _fieldName = customFieldName || sharedObj.fieldName;
    let _extraOfFieldName = {};
    if(extra && extra.extraData && extra.extraData.Columns){
      _extraOfFieldName = extra.extraData.Columns[_fieldName];
    }
    return {
      cell,
      row,
      extra: extra,      
      extraOfFieldName: _extraOfFieldName,
      fnList: sharedObj.fnList,
      more: sharedObj.more,
      fieldName: _fieldName,
    }
  },
  getHeader(cCell,{customFieldName}={}){
    const {cell,row,extra,extraOfFieldName,fieldName,more} = MyTableCellHelpers.getCommonProps(cCell,{customFieldName});
    if(extraOfFieldName && extraOfFieldName.Header){
      return extraOfFieldName.Header;
    }
    return fieldName;
  },
  getCanShow(cCell,{customFieldName}={}){
    const {cell,row,extra,extraOfFieldName,fieldName,more} = MyTableCellHelpers.getCommonProps(cCell,{customFieldName});    
    let _canShow = true;
    if(extraOfFieldName && extraOfFieldName.CanShow!=null){
      _canShow = extraOfFieldName.CanShow;
    }
    return _canShow;
  },
  getCanEdit_v2(cCell,{customFieldName}={}){
    const {cell,row,extra,extraOfFieldName,fieldName,more} = MyTableCellHelpers.getCommonProps(cCell,{customFieldName});    
    let _canEdit = false;
    if(extraOfFieldName && extraOfFieldName.CanEdit!=null){
      _canEdit = extraOfFieldName.CanEdit;
    }
    //IsLocked
    if(row.IsLocked===true){
      return false;
    }
    //more
    if(_canEdit){
      if(more.fCanEdit==null){
        if(more.canEditF!=null){ more.fCanEdit= more.canEditF};
      }
      if(typeof more.fCanEdit == 'boolean'){
        _canEdit = more.fCanEdit;
      }
      else if(typeof more.fCanEdit == 'string'){
        if(more.fCanEdit.length>0){          
          if(row[more.fCanEdit]!=null){
            _canEdit = row[more.fCanEdit];
          }
          else if(more.fCanEdit.indexOf(',')>-1){
            let _arrcanEditF = more.fCanEdit.split(',');
            if(_arrcanEditF && _arrcanEditF.length>0){
              for(let f of _arrcanEditF){
                if(row[f]==false){//only need one cannot edit -> cannot edit
                  _canEdit = false;
                  break;                  
                }
              }
            }
          }
        }        
      }

      if(more.lockF!=null || more.fLock!=null){ 
        let _fLock = more.lockF || more.fLock;
        if(typeof _fLock == 'boolean'){
          _canEdit = !_fLock;
        }
        else if(typeof _fLock == 'string'){
          if(_fLock.length>0){          
            if(row[_fLock]!=null){
              _canEdit = !row[_fLock];
            }
            else if(_fLock.indexOf(',')>-1){
              let _arrLock = _fLock.split(',');
              if(_arrLock && _arrLock.length>0){
                for(let f of _arrLock){
                  if(row[f]==true){//only need one lock edit -> cannot edit
                    _canEdit = false;
                    break;                  
                  }
                }
              }
            }
          }        
        }
      };
    }
    return _canEdit;
  },
  getCanEdit(cCell,fieldNameOther){
    // const {cell,row,extra} = cCell.props;
    // const {more,fieldName} = cCell.getSharedObj();
    // let _fieldName = fieldNameOther || fieldName;
    let _canEdit = MyTableCellHelpers.getCanEdit_v2(cCell,{customFieldName:fieldNameOther});
    // console.warn("getCanEdit:",cCell,fieldNameOther,extra);
    //extra.CanEdit
    // if(fieldNameOther){
    //   let _extraOfFieldName = MyTableCellHelpers.getExtraColumn(cCell,fieldNameOther);
    //   // console.warn("getCanEdit 2:",fieldNameOther,_extraOfFieldName);
    //   if(typeof _extraOfFieldName.CanEdit == 'boolean'){
    //     _canEdit = _extraOfFieldName.CanEdit;
    //   }
    // }
    // else{
    //   if(extra && typeof extra.CanEdit == 'boolean'){
    //     _canEdit = extra.CanEdit;
    //   }
    // }
    // //IsLocked
    // if(row.IsLocked===true){
    //   return false;
    // }
    // //more
    // if(_canEdit){
    //   if(more.fCanEdit==null){
    //     if(more.canEditF!=null){ more.fCanEdit= more.canEditF};
    //   }
    //   if(typeof more.fCanEdit == 'boolean'){
    //     _canEdit = more.fCanEdit;
    //   }
    //   else if(typeof more.fCanEdit == 'string'){
    //     if(more.fCanEdit.length>0){          
    //       if(row[more.fCanEdit]!=null){
    //         _canEdit = row[more.fCanEdit];
    //       }
    //       else if(more.fCanEdit.indexOf(',')>-1){
    //         let _arrcanEditF = more.fCanEdit.split(',');
    //         if(_arrcanEditF && _arrcanEditF.length>0){
    //           for(let f of _arrcanEditF){
    //             if(row[f]==false){//only need one cannot edit -> cannot edit
    //               _canEdit = false;
    //               break;                  
    //             }
    //           }
    //         }
    //       }
    //     }        
    //   }

    //   if(more.lockF!=null || more.fLock!=null){ 
    //     let _fLock = more.lockF || more.fLock;
    //     if(typeof _fLock == 'boolean'){
    //       _canEdit = !_fLock;
    //     }
    //     else if(typeof _fLock == 'string'){
    //       if(_fLock.length>0){          
    //         if(row[_fLock]!=null){
    //           _canEdit = !row[_fLock];
    //         }
    //         else if(_fLock.indexOf(',')>-1){
    //           let _arrLock = _fLock.split(',');
    //           if(_arrLock && _arrLock.length>0){
    //             for(let f of _arrLock){
    //               if(row[f]==true){//only need one lock edit -> cannot edit
    //                 _canEdit = false;
    //                 break;                  
    //               }
    //             }
    //           }
    //         }
    //       }        
    //     }
    //   };
    // }
    return _canEdit;
  },
  getFnList(cCell){
    const {cell,row,extra} = cCell.props;
    if(cCell._fnList){
      return cCell._fnList;
    }
    cCell._fnList = {};
    if(extra && extra.fnList){
      cCell._fnList = extra.fnList;
    }
    return cCell._fnList;
  },
  getMoreOfCell(cCell){
    const {cell,row,extra,custom} = cCell.props;
    if(cCell._moreObj){
      return cCell._moreObj;
    }
    let _more = {};
    if(extra && extra.Type && extra.Type.more){
      try {
        if(typeof extra.Type.more=="string"){
          _more = JSON.parse(extra.Type.more);
        }
        else if(typeof extra.Type.more=="object"){
          _more = extra.Type.more;
        }        
      } catch (error) {
        console.warn("getMoreOfCell error more json:",extra.Type);
      }
    }
    if(custom){
      _more = Object.assign(_more,custom);
    }
    if(row && row.UI_MoreCell){
      const fieldName = MyTableCellHelpers.getFieldNameOfCell(cCell);
      if(row.UI_MoreCell[fieldName]){
        _more = Object.assign(_more,row.UI_MoreCell[fieldName]);
      }
    }
    cCell._moreObj = _more;
    return cCell._moreObj;
  },
  getDateFormat(cCell,df){
    //{extra,row,more,df}
    let _more = MyTableCellHelpers.getMoreOfCell(cCell)
    let _format = df || HConfig.fnList.getConfig('dateFormat');
    if(_more.dateFormat){
      _format = _more.dateFormat;
    }
    else if(_more.format){
      _format = _more.format;
    }
    // console.log("date Format:",_format);
    return _format;
  },
  callRequestUpdate(cCell,{customRow,customFieldName,newValue,forceUpdate,hideMsgUpdate,successCallBack}={}){
    const {cell,extra} = cCell.props;
    const row = customRow || cCell.props.row;
    const fieldName = customFieldName || MyTableCellHelpers.getFieldNameOfCell(cCell);
    const fnList = MyTableCellHelpers.getFnList(cCell);
    let _cellFromRow = row[fieldName];    
    let _fnRequestUpdate = fnList.fnRequestUpdate;    
    // let _fnRequestUpdateMaster = fnList.fnRequestUpdateMaster;
    // console.warn("callRequestUpdate:",fnList,_cellFromRow,row);
    if(_fnRequestUpdate){
      let _canUpdate = false;
      if (newValue!==_cellFromRow && !(_cellFromRow==null && newValue==="")){// false == "", fix
        _canUpdate = true;
        if(typeof _cellFromRow=='number' && newValue==_cellFromRow){//fix truong hop so !== chuoi
          _canUpdate = false;
        }
      }
      else if(newValue==_cellFromRow){//Cho phep update khi 2 gia tri bang nhau va dang bi loi
        if(row["_UI_Error"] && row["_UI_Error"][fieldName]!=null){ _canUpdate = true; }
        else if(forceUpdate==true){ console.warn('newValue forceUpdate: ', newValue,_cellFromRow,forceUpdate); _canUpdate = true; }
      }
      if(_canUpdate){
        if(row["_UI_Loading"]==null){ row["_UI_Loading"] = {}; }
        row["_UI_Loading"][fieldName] = true;
        if(cCell && cCell.forceUpdate){          
          cCell.forceUpdate();
        }
        _fnRequestUpdate(row, fieldName, newValue, {
          component: cCell,
          hideMsgUpdate: hideMsgUpdate,
          fnUpdateUILoading: function(isLoading,opts){
            if(row["_UI_Loading"]==null){ row["_UI_Loading"] = {}; }
            row["_UI_Loading"][fieldName] = isLoading;
            if(opts && opts.component && opts.component.forceUpdate){ opts.component.forceUpdate(); } 
          },
          fnUpdateUIError: function(msg,opts){
            if(row["_UI_Error"]==null){ row["_UI_Error"] = {}; }
            if(msg==null){//msg == null nghia la xoa error di
              delete row["_UI_Error"][fieldName];
            }
            else{ row["_UI_Error"][fieldName] = { msg: msg, }; }   
            if(opts && opts.component && opts.component.forceUpdate){ opts.component.forceUpdate(); }         
          },
          fnSuccessCallBack: function(response,opts){
            if(successCallBack){
              successCallBack(response,opts);
            }
          }
        });
      }
    }
    // if(_fnRequestUpdateMaster){
    //   _fnRequestUpdateMaster(row)
    // }
  },
  getSourceSelect(cCell){
    const {cell,row,extra} = cCell.props;
    let _options = [];

    const fnList = MyTableCellHelpers.getFnList(cCell);
    let _fnGetSourceSelect = fnList.fnGetSourceSelect;    
    let _sourceList = fnList.fnGetOptions && fnList.fnGetOptions();
    let _sourceInExtra = "";
    if(extra && extra.Type && extra.Type.source){
      _sourceInExtra = extra.Type.source;
    }

    if(_fnGetSourceSelect){
      _options = _fnGetSourceSelect({
        key: _sourceInExtra
      });
    }
    else if(_sourceInExtra != null && _sourceInExtra.indexOf('.')>0){
      let _field1 = _sourceInExtra.split('.')[0];
      let _field2 = _sourceInExtra.split('.')[1];
      let _sourceField = extra.Type.sourceField;
      let _valueSourceField = row[_sourceField];

      if(_sourceList){
        if(_sourceList[_field1]!==null && _sourceList[_sourceInExtra]==null){
          let _obj = HUtils.arrayObj2ObjWithKey(_sourceList[_field1], 'Value');
          if(_obj){
            let _keys = Object.keys(_obj);
            let _newObj = {};
            for(let i of _keys){
              _newObj[i] = _obj[i][_field2];
            }
            _sourceList[_sourceInExtra] = _newObj;
          }
        }
        if(_sourceList[_sourceInExtra]!=null && _valueSourceField!=null){
          _options = _sourceList[_sourceInExtra][_valueSourceField];
        }
      }
    }
    else if(_sourceInExtra!=null && _sourceInExtra.startsWith("[")==true){
      try {
        _options = JSON.parse(_sourceInExtra);
      } catch (error) {
        console.warn("Parse Source option error:",extra);
      }
    }
    else if(_sourceInExtra!=null && _sourceInExtra.startsWith("f")==true){
      let _fieldName = _sourceInExtra.slice(1,_sourceInExtra.length);
      if(row[_fieldName]!=null && Array.isArray(row[_fieldName])){
        _options = row[_fieldName];
      }      
    }
    return _options;
  },
  updateRowWithData({row,data,fieldId}={}){
    if(row && data && row[fieldId]==data[fieldId]){
      for(let _key of Object.keys(data)){
        if(_key.startsWith('_')==false){
          row[_key] = data[_key];
        }
      }
    }
  },
  More:{
    getConfigByKeyInMore(cCell,key,df){
      const {cell,row,extra} = cCell.props;
      const {more} = cCell.getSharedObj();
      let _configByKeyInMore = df;
      let _more = more || MyTableCellHelpers.getMoreOfCell(cCell);
      if(_more && key && key.length>1){
        if(_more[key]){
          _configByKeyInMore = _more[key];
        }        
        if(key.length>1){
          let _fKey = `f${key.charAt(0).toUpperCase() + key.slice(1)}`;
          if(row[_fKey]){
            _configByKeyInMore = row[_fKey];
          }
        }        
      }      
      return _configByKeyInMore;
    },
    getNewLinkType(cCell,{df}={}){
      const more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _type = df || (HConfig.fnList.getConfig('defaultNewWindowCellLink')==true?'_blank':'_self');
      if(more.newWindow===true){
        _type = '_blank';
      }
      else if(more.newWindow===false){
        _type = '_self';
      }
      return _type;
    },
    parseResponse(cCell,response){
      let _more =MyTableCellHelpers.getMoreOfCell(cCell);

      if(response && response.Data){
        if(response.Data.FullPath){
          let _data = response.Data;
          if (_more.autoOpenLink==true){
            HLink.openExternalUrl(_data.FullPath,'_blank')
          }
          else{
            alert('_data.FullPath')
            // MyDialog.Helper.show({
            //   title: 'Link',
            //   msg: (
            //     <div style={{wordWrap:'break-word'}}>
            //       <div>{_data.FullPathInfo}</div>
            //       <a href={_data.FullPath} target="_blank">{_data.FullPath}</a>
            //     </div>
            //   )
            // });
          }
        }
      }
    },
    needReload(cCell,response){
      let {row} = cCell.props;
      let _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _fnList = MyTableCellHelpers.getFnList(cCell);

      if(_more.needReloadPage===true){
        // location.reload()
        return;
      }

      //ReExpand
      if(_more && _more.needReExpand===true && _fnList && _fnList.fnGetExpandList){
        let _cExpandList = _fnList.fnGetExpandList();
        if(_cExpandList && _cExpandList[row.Id] && _cExpandList[row.Id].reExpand){
          _cExpandList[row.Id].reExpand();
        }
      }

      if(_more && _more.needReloadOptionsAndList==true){
        if(_fnList && _fnList.fnReloadOptionsAndList){
          _fnList.fnReloadOptionsAndList();
          return;
        }
      }

      if(_more && _more.needReloadRow==true && response && response.Data){
        MyTableCellHelpers.updateRowWithData({
          row,
          data:response.Data,
          fieldId: "Id"
        });
        if(_fnList && _fnList.fnForceUpdateTable){
          _fnList.fnForceUpdateTable();
        }
      }
      
      if( _more.needReload===true || _more.needReloadList===true || _more.needReloadTable===true){
        if(_fnList && _fnList.fnReloadTable){
          _fnList.fnReloadTable();
        }
      }      
    },
    getConfigEditIcon(cCell){
      return MyTableCellHelpers.More.getConfigByKeyInMore(cCell,"configEditIcon",{});
      /**
       * {
       *  using: true, //check if false
       *  fieldName: 'ortherFieldName', //defaut that column
       *  type: 'text', // type 
       * }
       */
    },
    getTextDisplay({cCell,df,icon,title,hasIconAndText,options,optionValue}){
      const {extra,row,cell} = cCell
      let _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _display = df || "";
      let _icon = null;
      if(_more){
        if(_more.fDisplay && row[_more.fDisplay]){
          _display = row[_more.fDisplay];
        }
        else if(_more.fShow && row[_more.fShow]){
          _display = row[_more.fShow];
        }
        else if(options && optionValue){
          _display = HOptions.getTextDisplayOfValueFromOptions(options,optionValue) || cell;
        }
        else if(title){
          _display = title;
        }
        else if(cell){
          _display = cell;
        }

        if(icon){
          _icon = (
            <i className={`fa ${icon}`} />
          )
        }
        if(_icon){
          if(hasIconAndText===true){
            _display = (
              <div>
                {_icon} {_display}
              </div>
            )
          }
          else{
            _display = _icon;
          }
        }        
      }
      return _display;
    },
    getConfigModal(cCell){
      const more = MyTableCellHelpers.getMoreOfCell(cCell)
      let _configModal = {};
      if (more && more.modal && typeof more.modal==="object"){
        _configModal = more.modal;
      }
      return _configModal;
    },
    getFormat(cCell,df){
      const more = MyTableCellHelpers.getMoreOfCell(cCell)
      let _format = df || "";
      if (more && more.format){
        _format = more.format;
      }
      return _format;
    },
    getConfigRequest(cCell){
      const _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _request = {
        method: _more && _more.method || "POST"
      }
      if(_more.apiUrl){
        _request.url = _more.apiUrl;
      }
      else if(_more.url){//Dang dung o type api
        _request.url = _more.url;
      }
      if(_request.url){
        if(_request.url.indexOf("[")>-1){
          // _request.url = M.SquareBracket.replace(_request.url,row);
        }
      }

      if(_request.url==null){
        if(_more.apiPath){
          _request.path = _more.apiPath;
        }
        else{
          let _fnList = MyTableCellHelpers.getFnList(cCell);
          let _fnGetApiPath = _fnList.fnGetApiPath;
          if(_fnGetApiPath){
            _request.path = _fnGetApiPath();
          }        
        }      
        if(_more.apiName){
          _request.name = _more.apiName;
        } 
        if(_more.apiNameAdd){//Dung o cac type addable
          _request.name = _more.apiNameAdd;
        } 
        
        if(_request.name){
          if(_request.name.indexOf("[")>-1){
            // _request.name = M.SquareBracket.replace(_request.name,row);
          }
        }
      }        
      return _request;
    },
    getQuery(cCell){//fId,queryString,query
      // console.warn('extra,row,more', extra,row,more);
      const {row} = cCell.props;
      let _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _fnList = MyTableCellHelpers.getFnList(cCell);

      let _queryObj = {};

      if(row.ProjectId){//Tu dong gan ProjectId
        _queryObj.ProjectId = row.ProjectId;
      }
      if(_more.fProjectId && row[_more.fProjectId]){
        _queryObj.ProjectId = row[_more.fProjectId];
      }

      if (_more.fID){//Gan fId
        _queryObj[_more.fID] = row.Id;
      }      

      let _queryString = _more.queryString;
      if (typeof(_queryString)==='string'){
        if(_queryString){
          // _queryString = M.SquareBracket.replace(_queryString,row);
          try {
            let _query = JSON.parse(_queryString);
            if (_query!=null){
              _queryObj = Object.assign(_queryObj,_query);
            }
          } catch (error) {
            console.warn("Parse json _queryString error:",_queryString,error);
          }
        }
      }
      if(_more.queryConfig){
        if(_more.queryConfig.hasFilter){
          if(_fnList && _fnList.fnGetFilterQuery){
            let _filterQuery=_fnList.fnGetFilterQuery();
            if(_filterQuery && Object.keys(_filterQuery).length>0){
              _queryObj = Object.assign(_queryObj,{
                FilterQuery: _filterQuery
              });  
            }              
          }
        }
        if(_more.queryConfig.hasColumnShow){
          if(_fnList && _fnList.fnGetColumnShowQuery){
            _queryObj = Object.assign(_queryObj,_fnList.fnGetColumnShowQuery());    
          }
        }
      }

      if(_more.queryFromParent && _fnList && _fnList.fnGetParentData){        
        let _queryFromParent = {};
        let _parentData = _fnList.fnGetParentData();
        let _allKeys = Object.keys(_more.queryFromParent);
        if(_parentData){
          for(let k of _allKeys){
            _queryFromParent[k] = _parentData[_more.queryFromParent[k]];
          }
        }   
        _queryObj = Object.assign(_queryObj,_queryFromParent);        
      }
      return _queryObj;
    },
    getConfigBadge(cCell, {df}){
      let _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _configBadge = df || {};
      if(_more.badge && typeof _more.badge =="object"){
        _configBadge = Object.assign(_configBadge,_more.badge);
      }
      return _configBadge;
    },
    getConfigFile(cCell, {df}){
      let _more = MyTableCellHelpers.getMoreOfCell(cCell);
      let _configFile = df || {};
      if(_more.file && typeof _more.file =="object"){
        _configFile = Object.assign(_configFile,_more.file);
      }
      return _configFile;
    },
  },
  createExtraFromExtraData({fieldName,extraData,fnList}){
    let _extra = {};
    if(extraData && extraData.Columns && extraData.Columns[fieldName]){
      _extra = extraData.Columns[fieldName];
    }
    return {
      ..._extra,
      fieldName: fieldName,
      extraData: extraData,
      fnList: fnList,
    }    
  }
}

export default MyTableCellHelpers;