import React from 'react'
import HUtils from '../../../helpers/Utils'
class MyUI_IFrame extends React.Component {    
  componentDidMount(){    
    window.addEventListener("message", this._receiveMessage, false);
    //using in iframe
    // window.parent.postMessage({ message: "getAppData", value: MyDataToPass }, "*");
  }
  _getIdIframe=()=>{
    const {className,style,src,idIframe} = this.props;
    return idIframe || "myui-iframe"
  }
  _receiveMessage = (event) => {
    const {onReceiveMessage} = this.props;
    console.warn("_receiveMessage:",event);
    if(onReceiveMessage){
      onReceiveMessage(event.data);//data.message va data.value
    }
    // const message = event.data.message;
    // switch (message) {
    //   case 'getAppData':
    //     break;
    // }
  }
  render(){
    const {className,style,src,styleIframe} = this.props;    
    return (
      <div className={className} style={{width:'100%',height:'100%',...style}}>
        <iframe id={this._getIdIframe()} src={src} style={{width:'100%',height:'100%',...styleIframe}}/>
      </div>
    )
  } 
}

export default MyUI_IFrame