import React from 'react'
import './styles/common.css'

class MyUI_MCSBillInfo extends React.Component {
    render(){
        const {row} = this.props;
        // console.warn("row", row)
        if(row){
            const _IONumber = row.IONumber;
            const _PaymentTypeName = row.PaymentTypeName ;
            const _PaymentTypeShipId_Text  = row.PaymentTypeShipId_Text ;
            const _MoneyBillCOD_Text = row.MoneyBillCOD_Text;
            const _SupplierDeliveryName = row.SupplierDeliveryName;
            // const _TotalMoneyShip_Text = row.TotalMoneyShip_Text;
            const _Bill_Note = row.Bill_Note;
            const _StatusDelivery_Text = row.StatusDelivery_Text;
            const _type = _PaymentTypeName || _PaymentTypeShipId_Text || "";
            return(
                <div className="mtc-cs_billinfo">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{fontWeight: 500, textDecoration: "underline", color: "var(--color-primary)"}}>
                            {_IONumber}
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {_type === "COD" && _MoneyBillCOD_Text &&
                                <div style={{padding: "3px 6px", backgroundColor: "var(--color-warning)", color: "#fff", borderRadius: 4, fontWeight: 500}}> COD {_MoneyBillCOD_Text}</div>
                            }
                            {
                                _StatusDelivery_Text && 
                                <div style={{padding: "3px 6px", backgroundColor: "var(--color-secondary)", color: "#fff", borderRadius: 4, fontWeight: 500, marginLeft: 5, minWidth: 80, textAlign: "center"}}>{_StatusDelivery_Text}</div>
                            }
                        </div>
                    </div>
                    <div style={{marginTop: 10}}>
                        {
                            _SupplierDeliveryName &&
                            <div>{_SupplierDeliveryName}</div>
                        }
                        {
                            _Bill_Note &&
                            <div style={{backgroundColor: "var(--color-alert-msg-bg)", padding: "2px", whiteSpace: "pre-line"}}>{_Bill_Note}</div>
                        }
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default MyUI_MCSBillInfo