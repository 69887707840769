import React from 'react'


const MyLib = {
  addTypes(key,fnUI){
    if(MyLib.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyLib.Types[key]=fnUI;
    }
  },
  Types:{}
}

export default MyLib;
