import React from 'react';
import MyChartTypes from '../../../../components/MyChart/MyChartTypes'
function ComponentLoading(props) {
  return (
    <div></div>
  )
}

// const GG_Bar = React.lazy(() => import('./Types/gg_bar')); //done
// const GG_Pie = React.lazy(() => import('./Types/gg_pie')); //done
// const GG_OrgChart = React.lazy(() => import('./Types/gg_orgchart'));
const GG_Column = React.lazy(() => import('../../../../components/MyChart/Types/gg_column')); //done
// const GG_Combo = React.lazy(() => import('./Types/gg_combo')); //done
// const GG_Line = React.lazy(() => import('./Types/gg_line')); //done
// const GG_Area = React.lazy(() => import('./Types/gg_area')); //done
// const GG_TimeLine = React.lazy(() => import('./Types/gg_timeline')); //done

const CJS_Line = React.lazy(() => import('../../../../components/MyChart/Types/cjs_line'));
const CJS_Bar = React.lazy(() => import('../../../../components/MyChart/Types/cjs_bar'));
const CJS_Pie = React.lazy(() => import('../../../../components/MyChart/Types/cjs_pie'));
const CJS_Doughnut = React.lazy(() => import('../../../../components/MyChart/Types/cjs_doughnut'));
const CJS_Polar_Area = React.lazy(() => import('../../../../components/MyChart/Types/cjs_polar_area'));
const CJS_Radar = React.lazy(() => import('../../../../components/MyChart/Types/cjs_radar'));
const CJS_Mixed = React.lazy(() => import('../../../../components/MyChart/Types/cjs_mixed'));

const AppMyChart = {
  init(){
    MyChartTypes.addTypes('gg_column',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <GG_Column {...props}/> </React.Suspense> )
    });  
    MyChartTypes.addTypes('cjs_line',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Line {...props}/> </React.Suspense> )
    });  
    MyChartTypes.addTypes('cjs_bar',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Bar {...props}/> </React.Suspense> )
    });  
    MyChartTypes.addTypes('cjs_pie',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Pie {...props}/> </React.Suspense> )
    }); 
    MyChartTypes.addTypes('cjs_doughnut',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Doughnut {...props}/> </React.Suspense> )
    });   
    MyChartTypes.addTypes('cjs_polar_area',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Polar_Area {...props}/> </React.Suspense> )
    }); 
    MyChartTypes.addTypes('cjs_radar',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Radar {...props}/> </React.Suspense> )
    });  
    MyChartTypes.addTypes('cjs_mixed',(props)=>{
      return( <React.Suspense fallback={<ComponentLoading />}> <CJS_Mixed {...props}/> </React.Suspense> )
    }); 
  }
}
AppMyChart.init();
export default AppMyChart;