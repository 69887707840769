import React from 'react'
import MyTableCellTypes from '../../../../components/MyTableCell/MyTableCellTypes';

import CSBillInfo from './Types/cs_billinfo'
import Textarea from '../../../../components/MyTableCell/Types/textarea';
import Checkbox from '../../../../components/MyTableCell/Types/checkbox';
import Readonly from '../../../../components/MyTableCell/Types/readonly';
import NumberDecimal from '../../../../components/MyTableCell/Types/number_decimal';

const AppMyUI = {
    init(){
      MyTableCellTypes.addTypes('cs_billinfo',(props)=>{
        return ( <CSBillInfo {...props}/> )
      });
      MyTableCellTypes.addTypes('readonly',(props)=>{
        return ( <Readonly {...props}/> )
      });
      MyTableCellTypes.addTypes('checkbox',(props)=>{
        return ( <Checkbox {...props}/> )
      });
      MyTableCellTypes.addTypes('textarea',(props)=>{
        return ( <Textarea {...props}/> )
      });
      MyTableCellTypes.addTypes('number_decimal',(props)=>{
        return ( <NumberDecimal {...props}/> )
      });
    },
}
AppMyUI.init();
export default AppMyUI;