import React from 'react'
import './style.css';

class Switch extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
    dataOn: 'On',
    dataOff: 'Off',
    disabled: false,
    defaultChecked: null,
    onChange: function(){}
  }
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultChecked
    }
  }
  render(){
    const {value} = this.state;
    const {dataOn,dataOff,onChange} = this.props;
    return (
      <label className={`mcui-sw mcui-sw-green ${value===true?'mcui-checked':''}`} onClick={()=>{
        this.setState({
          value: !this.state.value
        },()=>{
          onChange(this.state.value);
        })
      }}>
        <span className="mcui-sw-label" data-on={dataOn} data-off={dataOff}></span>
        <span className="mcui-sw-handle"></span>
      </label>
    )
  }
}

export default Switch