import React from 'react'
import './style.css';

class C1 extends React.Component {
  static propTypes = {}
  static defaultProps = {}
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render(){
    const {left,right} = this.props;
    return (
      <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
        <div style={{flex:1}}>{left()}</div>
        <div style={{flex:1}}>{right()}</div>
      </div>
    )
  }
}

export default C1