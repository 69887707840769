import React from 'react'
import {
  CModal, CModalBody, CModalHeader, CModalFooter, CButton,
} from '../../components/MyCore';
import MyUI from '../MyUI/MyUI';
import HText from '../../helpers/Text';
import MyPageFromConfig from '../MyPage/MyPageFromConfig';
import "./styles.css";
class MyModal extends React.Component {  
  constructor(props){
    super(props);
    this.state = {
      show: false,
      showLoading: false,
      component: null,
      opts: null,
    }

    this._fnFromModal = {
      fnClose: ()=>{
        this.hide();
      },
      fnShowLoading: ()=>{
        this.setState({
          showLoading: true
        })
      },
      fnHideLoading: ()=>{
        this.setState({
          showLoading: false
        })
      }
    }
  }

  componentDidMount(){
    const {type} = this.props;
    if(type=='msg'){
      MyModal.Helpers.componentMsg = this;
    }
    else if(type=='confirm'){
      MyModal.Helpers.componentConfirm = this;
    }
    else if(type=='form'){
      MyModal.Helpers.componentForm = this;
    }
    else if(type=='image_gallery'){
      MyModal.Helpers.componentImageGallery = this;
    }
    else if(type=='myui2'){
      MyModal.Helpers.component2 = this;
    }
    else if(type=='myui3'){
      MyModal.Helpers.component3 = this;
    }
    else{
      MyModal.Helpers.component = this;
    }    
  }
  clearTimeOutHide=()=>{
    if(this._timerHide){
      clearTimeout(this._timerHide);
    }
  }
  showComponent=(component,opts)=>{
    this.clearTimeOutHide();
    this.setState({
      show: true,
      component,
      opts,
    })
  }
  showFromMyUI=(type,props,opts)=>{
    // console.warn('type', props)
    this.clearTimeOutHide();
    this.setState({
      show: true,
      component: (
        <MyUI type={type} ref={(r)=>{
          this._cContent = r;
        }} {...props} fnFomModal={this._fnFromModal}/>
      ),
      opts,
    })
  }
  showFromMyPage=(type,props,opts)=>{
    // console.warn("showFromMyPage:",type,props,opts);
    this.clearTimeOutHide();
    this.setState({
      show: true,
      component: (
        <MyPageFromConfig type={type} ref={(r)=>{
          this._cContent = r;
        }} {...props} fnList={{
          fnFomModal: this._fnFromModal
        }}/>
      ),
      opts,
    },()=>{
      // console.warn("aaa:",this.state);
    })
  }
  forceUpdateContent=()=>{
    // console.warn("forceUpdateContent");
    this.forceUpdate();
    if(this._cContent && this._cContent.forceUpdate){
      this._cContent.forceUpdate();
    }
  }
  hide = ()=>{
    this.setState({
      show: false,      
    },()=>{
      this._timerHide = setTimeout(()=>{
        this.setState({
          component: null,
          opts: null,
          showLoading: false
        })
      },200);      
    })
  } 
  _onClickOK=()=>{
    const {show,component} = this.state;
    const opts = this.state.opts || {};
    const {onOK} = opts;
    if(onOK){
      onOK(this,this._fnFromModal);
    }
  }
  render(){
    const {show,component} = this.state;
    const opts = this.state.opts || {};
    const {title,showHeader,showFooter,isFullContent,backdrop,closeOnBackdrop,size,className,closeButton,styleBody} = opts;
    // size?: '' | 'sm' | 'lg' | 'xl'; //extra: size-full-fixed
    // console.warn("render MyModal", component);
    return (
      <div className={className}>
        <CModal show={show} onClose={this.hide} backdrop={backdrop} fade={true} closeOnBackdrop={closeOnBackdrop} size={size} >
          {
            isFullContent===true && component!=null ?
            component
            :
            <React.Fragment>
            {
              showHeader &&
              <CModalHeader closeButton={closeButton}>{title}</CModalHeader>
            }        
            <CModalBody style={styleBody}>
              {
                component!=null && component
              }
            </CModalBody>
            {
              showFooter &&
              <CModalFooter>                
                <CButton
                  color="secondary"
                  onClick={this.hide}
                >{HText.get('btn_close')}</CButton>
                {' '}
                <CButton color="primary" onClick={this._onClickOK} disabled={this.state.showLoading}>
                  {
                    this.state.showLoading && <MyUI type="loading_icon"/>
                  }
                  {HText.get('btn_ok')}
                </CButton>
              </CModalFooter>
            }
            </React.Fragment>
          }                
        </CModal>
      </div>
    )
  } 
}

MyModal.Helpers = {
  component: null,
  component2: null,
  component3: null,
  componentMsg: null,
  componentConfirm: null,
  componentImageGallery: null,
  componentForm: null,
  showMsg(msg,opts){
    if(MyModal.Helpers.componentMsg){
      MyModal.Helpers.componentMsg.showComponent(msg,{title:'Message',className:'mmd-msg',showHeader:true,...opts});
    }
  },
  showConfirm(msg,opts){
    if(MyModal.Helpers.componentConfirm){
      MyModal.Helpers.componentConfirm.showComponent(msg,{title:'Confirm',className:'mmd-confirm',showHeader:true,showFooter:true,...opts});
    }
  },
  showImageGallery(items,propsImageGallery,opts){
    if(MyModal.Helpers.componentImageGallery){
      MyModal.Helpers.componentImageGallery.showFromMyUI('react_image_gallery',{
        items: items,
        ...propsImageGallery,
      },{isFullContent:true,size:'xl',className:'mmd-image-gallery',showHeader:false,showFooter:false,...opts});
    }
  },
  showForm(configForm,propsForm,opts){
    if(MyModal.Helpers.componentForm){
      MyModal.Helpers.componentForm.showFromMyUI('form',{
        config: configForm,
        ...propsForm,
      },{isFullContent:false,size:'xl',title:'Form',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showMyUI(type,propsMyUI,opts){
    if(MyModal.Helpers.component){
      MyModal.Helpers.component.showFromMyUI(type,{
        ...propsMyUI,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showMyUI2(type,propsMyUI,opts){
    if(MyModal.Helpers.component2){
      MyModal.Helpers.component2.showFromMyUI(type,{
        ...propsMyUI,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showMyUI3(type,propsMyUI,opts){
    if(MyModal.Helpers.component3){
      MyModal.Helpers.component3.showFromMyUI(type,{
        ...propsMyUI,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showComponent(component,opts){
    if(MyModal.Helpers.component){
      MyModal.Helpers.component.showComponent(component,{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showComponent2(component,opts){
    if(MyModal.Helpers.component2){
      MyModal.Helpers.component2.showComponent(component,{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showMyPage(type,propsMyPage,opts){
    if(MyModal.Helpers.component){
      MyModal.Helpers.component.showFromMyPage(type,{
        ...propsMyPage,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }    
  },
  hide(){
    if(MyModal.Helpers.component){
      MyModal.Helpers.component.hide();
    }
  },
  hideModalForm(){
    if(MyModal.Helpers.componentForm){
      MyModal.Helpers.componentForm.hide();
    }
  },
  hideModalGallery(){
    if(MyModal.Helpers.componentImageGallery){
      MyModal.Helpers.componentImageGallery.hide();
    }
  }
}

export default MyModal