import {ToastContainer, toast} from 'react-toastify';
import MyModal from '../components/MyModal/MyModal';
import Text from '../helpers/Text';
import HConfig from "./Config"
const UI = {
  showMsgObj(msgObj){
    if(msgObj && typeof msgObj==="object"){
      if(msgObj && msgObj.msg){
        let _msg = msgObj.msg || ''
        let _type = msgObj.type || 'success'
        let _ui = msgObj.ui || 'toast';//toast,alert,sytem
        if(_ui=='toast'){
          UI.Toast.show(_type,_msg,msgObj.optionsToast);
        }
        else if(_ui=='alert'){
          UI.Alert.showMsg(msgObj.title||HConfig.fnList.getConfig('title'),_msg,()=>{},{
            type: _type
          });
        }
        else if(_ui=='system'){
          alert(msgObj.msg);
        }    
      } 
    }
  },
  Toast:{
    TYPE:{
      success: 'success',
      warn: 'warn',
      info: 'info',
      error: 'error',
    },
    show(type,msg){
      const options = {
        hideProgressBar: true,
        pauseOnHover: true,
        autoClose: 3000,
      };

      switch(type){
        case this.TYPE.success:
          toast.success(msg,options);break;
        case this.TYPE.warn:
          toast.warn(msg,options);break;
        case this.TYPE.info:
          toast.info(msg,options);break;
        case this.TYPE.error:
          toast.error(msg,options);break;
      }
    },
    showSuccess(msg){
      if(msg){
        if(typeof msg=="string"){
          if(msg.startsWith("[Warning]")){
            this.show(this.TYPE.warn,msg.replace("[Warning]",""));
          }
          else if(msg.startsWith("[Error]")){
            this.show(this.TYPE.error,msg.replace("[Error]",""));
          }
          else if (msg.startsWith("[Alert]")) {
            UI.Alert.showMsg(null, msg.replace("[Alert]", ""), ()=>{})
          }
          else{
            this.show(this.TYPE.success,msg);
          } 
        }
        else{
          UI.showMsgObj(msg)
        }               
      }  
    },
    showWarn(msg){
      this.show(this.TYPE.warn,msg);
    },
    showInfo(msg){
      this.show(this.TYPE.info,msg);
    },
    showError(msg){
      if(msg){
        if(msg.startsWith("[Warning]")){
          this.show(this.TYPE.warn,msg.replace("[Warning]",""));
        }
        else{
          this.show(this.TYPE.error,msg);
        }        
      }
    },
  },
  Alert:{
    show(title,msg,okCallBack){
      if(MyModal!=null && MyModal.Helpers){
        MyModal.Helpers.showConfirm(msg,{title, onOK: (cModal,{fnClose,fnShowLoading})=>{okCallBack();fnClose();}});
      }
    },
    showMsg(title,msg,okCallBack){
      this.show(title,msg,okCallBack)
    },
    showMsgQuestion(title,msg,okCallBack,cancelCallback,isAutoCloseOK){
      let _isAutoCloseOK = isAutoCloseOK!=null?isAutoCloseOK:true;
      this.show(title,msg, okCallBack)
    },
  },
  LoadingScreen: {
    cComponent: null,
    init(component){
      // console.log('init loading screen');
      UI.LoadingScreen.cComponent=component;
    },
    show(){
      // document.getElementById('loading_screen').style.visibility = 'visible';
      // console.log('show:',this.cComponent);
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showLoading();
      }
    },
    hide(){
      // document.getElementById('loading_screen').style.visibility = 'hidden';
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.hide();
      }
    },
    showError(msg,callback){
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showError(msg,callback);
      }
    },
    showCustomContent(customContent){
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showCustomContent(customContent);
      }
    },
  },
}
window.HUI = UI;
export default UI;