import React from 'react'
const MyLoadingComponent = (props) => {
  return (
    <div className="sk-spinner sk-spinner-pulse"></div>
  )
  // return (
  //   <div class="sk-rotating-plane"></div>
  // )  
}

export default MyLoadingComponent;