import React from 'react'
import MyTableFilterTypes from './MyTableFilterTypes';
import MyTableFilterHelpers from './MyTableFilterHelpers';
import HLocalStorage from '../../helpers/LocalStorage';
import classnames from 'classnames';
import "./styles.css";

class MyTableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      listFilterWithKey: {},
    };
    this._configItemByKey = {};
    this._countBuildItem = 0;//Dem so luong item build, check last item submit filter if has value
    this._currentValues = {};//key: field, value: value    
    this._initValuesFilter = {};//key: field, value: value //from Saved or defaultValue and for Saved
    // this._parseConfigItemByKey();
    this._parseLastFilter();
    console.warn("constructor mtf:",this.state)
  }    
  getCurrentValues=()=>{
    return this._currentValues;
  }
  getInitValuesFilter=()=>{
    return this._initValuesFilter;
  }

  _parseLastFilter=()=>{
    const {screenCode,options} = this.props;
    if(options && options.DefaultFilter){
      this._initValuesFilter = Object.assign(this._initValuesFilter,options.DefaultFilter);
    }
    if(screenCode){
      let _filterSaved = HLocalStorage.ConfigScreen.getCustomField({
        screenCode: screenCode,
        key: 'mtf'
      });
      if(_filterSaved){
        let _keys = Object.keys(_filterSaved);
        if(_keys && _keys.length>0){
          for(let k of _keys){
            this._initValuesFilter[k] =_filterSaved[k];
          }
        }
        console.log("_filterSaved:",_filterSaved,this._initValuesFilter);
      }            
    }    
  }

  _saveLocalFilter=()=>{
    const {screenCode} = this.props;
    console.log("_saveLocalFilter:",screenCode,this._currentValues,this._initValuesFilter,this.state.listFilterWithKey);
    let _configSave = {};
    let _keys = Object.keys(this._initValuesFilter);
    for(let k of _keys){
      let _moreOfItem = MyTableFilterHelpers.getMoreOfItem(this._configItemByKey[k]);
      // console.warn("_more if item:",_moreOfItem,k,this._configItemByKey);
      if(_moreOfItem && _moreOfItem.isSaved!=false){
        _configSave[k] = this._initValuesFilter[k];
      }
    }
    if(screenCode){
      HLocalStorage.ConfigScreen.setCustomField({
        screenCode: screenCode,
        key: 'mtf',
        data: _configSave
      });
    }
  }

  _loadLastFilterFromLocal=()=>{
    const {screenCode} = this.props;
  }

  _buildFilterForBootstrapTable=()=>{
    let _listFilterWithKey = this.state.listFilterWithKey;
    // console.log('_buildFilterForBootstrapTable:',_listFilterWithKey);
    let _cKeys = Object.keys(_listFilterWithKey);
    let _obj = {};
    if(_cKeys && _cKeys.length>0){      
      for(let k of _cKeys){
        let _arrOfItem = _listFilterWithKey[k];
        if(Array.isArray(_arrOfItem)){
          if(_arrOfItem && _arrOfItem.length>0){
            for(let i in _arrOfItem){
              let _item = _arrOfItem[i];
              if(_item){
                _obj = Object.assign(_obj,_item);
              }
            }
          } 
        }
        else{
          _obj = Object.assign(_obj,_arrOfItem);
        }               
      }
    }
    return _obj;
  }
  _getOuterStyle=(item)=>{
    let _style = {};
    if(item.isFull){
      _style.width="100%";
      _style.paddingRight="0px";
    }
    return _style;
  }

  _onChangeValueOfItem=(item,value,opts={})=>{
    // const {onChangeSearch,onChangeFilter} = this.props;
    console.log('_onChangeValueOfItem:',item,value,opts);    
    // if(item.type=="search123"){
    //   this._currentValues[item.fieldName] = value;      
    //   if(onChangeSearch){
    //     onChangeSearch(value);
    //   }
    // }
    // else{
    let _key = MyTableFilterHelpers.getKeyOfItem(item);
    this._initValuesFilter[_key] = value;
    let _filterOfItem = opts.filter;
    if(_filterOfItem){
      this.state.listFilterWithKey[_key] = _filterOfItem;
      if(_filterOfItem[_key]){
        if(_filterOfItem[_key].valueFilterServer){
          this._currentValues[_key] = _filterOfItem[_key].valueFilterServer;
        }
        else if(_filterOfItem[_key].value){
          this._currentValues[_key] = _filterOfItem[_key].value;
        }          
      }    
    }    
    else{
      delete this.state.listFilterWithKey[_key];
      delete this._currentValues[_key];
      delete this._initValuesFilter[_key];
    } 
    if(opts.justUpdateFilter!==true){
      this._submitCurrentFilter();
    }      
    // }
    if(opts.fromInit!==true){
      this._saveLocalFilter();
    }    
  }
  _submitCurrentFilter=({fromDefaultValue}={})=>{
    const {onChangeSearch,onChangeFilter} = this.props;
    let _build = this._buildFilterForBootstrapTable();
    console.log('_submitCurrentFilter _build:',_build,this._currentValues); 
    if(fromDefaultValue==true){
      if(_build && Object.keys(_build).length>0){
        if(onChangeFilter){
          onChangeFilter(_build);
        }
      }
    }
    else{
      if(onChangeFilter){
        onChangeFilter(_build);
      }
    }    
  }
  _getSourceList=()=>{
    const {options} = this.props;
    let _options = [];
    if(options){
      if(typeof options =="function"){
        _options = options();
      }
      else{
        _options = options;
      }
    }
    return _options;
  }
  _getDataList=()=>{
    const {dataList} = this.props;
    let _data = [];
    if(dataList){
      if(typeof dataList =="function"){
        _data = dataList();
      }
      else{
        _data = dataList;
      }
    }
    return _data;
  }
  _getContents=()=>{
    const {contents} = this.props;
    if(typeof contents == "function"){
      return contents();
    }
    return contents;
  }
  _renderContents=()=>{
    const {contents,configFilter} = this.props;
    let _ui = [];
    if(contents && contents.length>0){      
      // console.warn("set checkIsLastItem:");
      for(let i in contents){
        let _item = contents[i];
        let _type = _item.type;
        if(_type=="search" && _item.fieldName==null){
          _item.fieldName = "_SearchText";
        }
        this._configItemByKey[MyTableFilterHelpers.getKeyOfItem(_item)] = _item;
        let _className = MyTableFilterHelpers.getClassNameOfItem(_item,configFilter);
        if(MyTableFilterTypes.Types[_type]){
          // console.warn("add filter",_type,_item);
          _ui.push(
            <div key={i} style={this._getOuterStyle(_item)} className={classnames("mtf-item",_className)}>
              {
                MyTableFilterTypes.Types[_type]({                  
                  ..._item,
                  configOne: _item,
                  onChange:(value,opts)=>{
                    this._onChangeValueOfItem(_item,value,opts);
                  },                  
                  _getSourceList:this._getSourceList,
                  _getDataList:this._getDataList,
                  fnList:{
                    checkIsLastItem: ()=>{
                      // console.warn("checkIsLastItem:",this._countBuildItem,contents.length,_item);      
                      this._countBuildItem++;                
                      if(this._countBuildItem==contents.length-1){
                        return true;
                      }                      
                      return false;
                    },
                    getCurrentValues: this.getCurrentValues,
                    getInitValuesFilter: this.getInitValuesFilter,
                    submitCurrentFilter: this._submitCurrentFilter
                  }
                })
              }
            </div>
          );
        }
      }
    }
    return _ui;
  }

  render() {
    const {style,styleContainer,className,contents} = this.props;
    let _styleContainer = Object.assign({},styleContainer);
    return (
      <div className={classnames(className,'row no-print mf-container')} style={_styleContainer}>{this._renderContents()}</div>
    )
  }
}

export default MyTableFilter