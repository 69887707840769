import React from 'react'
const ButtonApi = React.lazy(() => import('./Types/api'));
const ButtonLink = React.lazy(() => import('./Types/link'));
const ButtonUpload = React.lazy(() => import('./Types/upload'));
const ButtonModal = React.lazy(() => import('./Types/modal_data'));
const ButtonActionClient = React.lazy(() => import('./Types/action_client'));
const ButtonMModal = React.lazy(() => import('./TypesMobile/m_modal'));

const MyButtonTypes = {
  addTypes(key,fnUI){
    if(MyButtonTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
        MyButtonTypes.Types[key]=fnUI;
    }
  },
  Types:{
    api:(props)=>{ return ( <ButtonApi {...props}/> ) },
    link:(props)=>{ return ( <ButtonLink {...props}/> ) },
    upload:(props)=>{ return ( <ButtonUpload {...props}/> ) },
    modal_data:(props)=>{ return ( <ButtonModal {...props}/> ) },
    action_client:(props)=>{ return ( <ButtonActionClient {...props}/> ) },
    m_modal: (props)=>{ return ( <ButtonMModal {...props}/> ) },
  }
}

export default MyButtonTypes;