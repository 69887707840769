const LocalStorage = {
  kPrefix: '',
  HConfig: null,
  initLocalStorage(HCofig,{kPrefix}={}){
    LocalStorage.HConfig = HCofig;
    if(kPrefix){
      LocalStorage.kPrefix = kPrefix;
    }
  },
  getString(key, df) {
    return localStorage.getItem(key) || df;
  },
  getObject(key, df) {
    let _s = this.getString(key);
    if (_s != null) {
      try {
        let _obj = JSON.parse(_s);
        return _obj;
      } catch (error) {
        console.warn("LocalStore getObject failed:", _s, error);
      }
    }
    return df;
  },
  setString(key, string) {
    if (key != null && string != null) {
      localStorage.setItem(key, string);
    } else {
      console.warn("LocalStore setString failed:", key, string);
    }
  },
  setObject(key, obj) {
    // console.warn('setObject', key, obj)
    if (key != null && obj != null) {
      let _s = JSON.stringify(obj);
      localStorage.setItem(key, _s);
    } else {
      console.warn("LocalStore setObject failed:", key, obj);
    }
  },
  ConfigScreen:{
    key:{
      prefix: 'config_',
      filter: 'filter',
    },
    getConfig(key){
      if(key!=null){
        // console.log('getConfig:',this.key.prefix + key);
        return LocalStorage.getObject(this.key.prefix + key,{});
      }
    },
    setConfig(key,obj){
      if(key!=null){
        return LocalStorage.setObject(this.key.prefix + key,obj);
      }
    },
    getCustomField({screenCode,key}){
      if(screenCode!=null){
        let _config = this.getConfig(screenCode);
        if(_config!=null && _config[key]!=null){
          return _config[key];
        }
      }
    },
    setCustomField({screenCode,key,data}){
      // console.warn('setObject', screenCode,key,data)
      if(key!=null){
        let _config = this.getConfig(screenCode)||{};
        if(_config){
          _config[key] = data;
        }
        this.setConfig(screenCode,_config);
      }
    },
    clearAllConfig(){
      if(localStorage!=null && Object.keys(localStorage)!=null){
        let _arrKeys = Object.keys(localStorage);
        for(let e of _arrKeys){
          if(e.startsWith(LocalStorage.ConfigScreen.key.prefix)==true){
            delete localStorage[e];
          }
        }
      }
    }, 
  }
}

export default LocalStorage;