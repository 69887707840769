import React from "react";
import MyTableCellExt from "./_ext";
import MyTableCellHelpers from "../MyTableCellHelpers";
import { MyUI } from "../../../apps/Components";

class MyTableCell_PickColor extends MyTableCellExt {
  _getMyClass = () => {
    return "mct-number_decimal";
  };
  _isValid=(value,pattern)=>{
    if(value!=null || value==""){      
      // console.warn("value",value);
      if(value==""){
        return true;
      }
      else{
        let _sValue = value.toString();
        let _indexOfDot = _sValue.indexOf(".");
        if(_indexOfDot>-1){
          if(_indexOfDot != _sValue.lastIndexOf(".")){
            return false;
          }
          else if(_sValue.endsWith(".")){
            return true;
          }
        }
        let _reg = new RegExp(pattern,"gi");
        return _reg.test(value);
      }
    }
    return false;
  }

  _replaceInvalid(value,pattern){
    let _value = value;
    if(value!=null){      
      let _reg = new RegExp(pattern,"gi");
      _value = _value.replace(_reg,"");
    }
    return _value;
  }

  _acceptChange(value){
    if(value==="" || value==="-" || value==="."){
      return true;
    }
    if(value!=null && value.length>0){
      if(value.endsWith(".") && value.indexOf(".")==value.length-1){
        return true;
      }
    }
  }

  _acceptChangeAutoReplace=(value)=>{
    if(value==="" || value==="-" || value==="."){
      return "";
    }
    if(value!=null && value.length>0){
      if(value.endsWith(".") && value.indexOf(".")==value.length-1){
        return value.replace(".","");
      }
    }
    return value;
  }
  renderContent = () => {
    const { row, cell } = this.props;
    const className = MyTableCellHelpers.getClassOfCell(this);
    const canEdit = !MyTableCellHelpers.getCanEdit(this);
    return (
      <MyUI
        type="input_text"
        defaultValue={cell}
        style={MyTableCellHelpers.getStyleOfCell(this)}
        className={className}
        disabled={canEdit}
        onBlur={(ev, newValue) => {
          let _newValueReplace = this._acceptChangeAutoReplace(newValue);
          MyTableCellHelpers.callRequestUpdate(this, {newValue:_newValueReplace });
        }}
      />
    );
  };
}

export default MyTableCell_PickColor;
