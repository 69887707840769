const HConstant = {
  UIFnPage:{
    fnRequestUpdate: "fnRequestUpdate",//fnRequestUpdate(row, fieldName, cellValue, opts)
    fnReloadList: "fnReloadList",//load lai list
    fnForceUpdateTable: "fnForceUpdateTable",//forceupdate table, chi moi co ben category
    fnGetCanEditOfField: "fnGetCanEditOfField",
    fnGetCanShowOfField: "fnGetCanShowOfField",
  },
  ExtraDataColumn:{
    CanEdit: 'CanEdit',
    CanShow: 'CanShow'
  }
}

export default HConstant;