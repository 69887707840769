/*eslint-disable eqeqeq */
import HLink from './Link';

const Dev = {
  isDebug: false,
  listDomainDebug: [],
  initDev(HConfig){
    if(HConfig && HConfig.fnList){
      Dev.listDomainDebug = HConfig.fnList.getConfig('listDomainDebug');
      if(Dev.listDomainDebug!=null){
        for(let i=0;i<Dev.listDomainDebug.length;i++){
          let _d = Dev.listDomainDebug[i];
          if(window.location.href.indexOf(_d)>-1){
            Dev.isDebug = true;
          }
        }
      }
    }
    if(window.location.href.indexOf('//localhost')>-1){
      Dev.isDebug = true;
    }
    if(window.location.href.indexOf('//192.168.')>-1){
      Dev.isDebug = true;
    }
    if(localStorage && localStorage.getItem('_DEBUG_')=="true"){
      Dev.isDebug = true;
    }
    if(Dev.isDebug!=true){
      console.log = function(){};
      console.warn = function(){};
    }    

    let _baseUrl = Dev.getBaseUrl();
    if(Dev.isDebug && _baseUrl && HConfig && HConfig.configApp){
      HConfig.configApp.BaseUrl = _baseUrl;
    }

    if(Dev.isDebug){
      if(document.getElementById("_dev_warning_")==null){
        var node = document.createElement("div");        
        node.setAttribute("id", "_dev_warning_");
        node.innerHTML  = "Mode DEV";
        node.onclick = function () {
          HLink.push('/dev/mode');
        };
        if(_baseUrl){
          node.innerHTML += "- Custom BaseURL: " + _baseUrl
        }
        document.body.appendChild(node);
      }
    }
  },
  getDevMode(){
    return localStorage.getItem('_DEBUG_')=="true"?true:false;
  },
  setDevMode(value){
    localStorage.setItem('_DEBUG_',value==true?"true":"false");
  },
  getBaseUrl(){
    return localStorage.getItem('_DEBUG_BaseURL_');
  },
  setBaseUrl(value){
    localStorage.setItem('_DEBUG_BaseURL_',value);
  }
}
window.HDev = Dev;
export default Dev;