const Text = {
  btn_cancel:'Hủy',
  btn_edit:'Chỉnh sửa',
  btn_change_pw: 'Đổi mật khẩu',//Done
  btn_close:'Đóng',
  btn_confirm:'Xác Nhận',
  btn_choosefile:'Chọn files',
  btn_uploadingfile:'Đang upload files...',
  btn_delete:'Xóa',
  btn_new:'Tạo mới',
  btn_ok:'Đồng Ý',
  btn_reset: 'Làm lại',
  btn_save:'Lưu',
  btn_saving:'Đang lưu ...',
  btn_login: 'Đăng nhập',
  btn_forgotpw: 'Quên mật khẩu?',
  btn_logout: 'Thoát',
  btn_uploadfile: 'Upload file',
  btn_refresh_cache: 'Refresh Cache',
  btn_clear_local_cache: 'Clear Local Cache',
  btn_submit:'Gửi',
  btn_tryagain:'Thử lại',//Done
  btn_bypass:'Bỏ qua',//Done
  btn_getstarted:'Get Started',
  btn_pickdatefromto: 'Chọn ngày',
  btn_orderlistreport: 'Order List',
  btn_homepage: 'Trang chủ',
  btn_reload: 'Tải lại',
  btn_myprofile: 'Trang cá nhân',
  chart_chose_chart:'Chọn loại Chart',
  chart_chose_info:'Chọn loại thông tin',
  confirm_create_new:'Bạn có chắc muốn thêm mới?',
  confirm_delete:'Bạn có chắc muốn xóa?',
  create_new: 'Tạo mới',
  msg_confirm_success: 'Xác nhận thành công',//Done
  msg_email_send: 'Đã gửi email',
  msg_input_check_before_send: 'Vui lòng điền đầy đủ thông tin trước khi gửi.',//Done
  msg_input_please: 'Vui lòng điền đầy đủ thông tin!',//Done
  msg_list_files_onsite: 'Files đang có (OnSite)',
  msg_list_files_plan: 'Files đang có (Plan)',
  msg_list_files: 'Files đang có',
  msg_loading: 'Đang tải dữ liệu...',//Loading...
  msg_loading_please_waiting: 'Đang tải dữ liệu, xin vui lòng chờ đợi!',
  msg_nodata: 'Không có dữ liệu!',//Done
  msg_nophoto: 'Không có hình nào!',//Done
  msg_confirm_file: 'Bạn có chắc muốn áp dụng file đã chọn?',
  msg_pw_confirm_wrong: 'Mật khẩu xác nhận không trùng khớp!',
  msg_pw_new_wrong: 'Mật khẩu mới phải khác mật khẩu cũ',
  msg_pw_enter_new: 'Vui lòng nhập mật khẩu mới!',
  msg_pw_input_confirm: 'Confirm new password:',//Done
  msg_pw_input_new: 'New password:',//Done
  msg_error_login_google: 'Liên kết Google thất bại! Hãy thử đăng xuất tài khoản google rồi thử lại.',
  msg_error:'Có lỗi xảy ra',
  msg_error_get_options:'Lỗi lấy Options!',
  msg_error_get_list:'Lỗi lấy List!',
  msg_error_upload_no_support: 'File không hỗ trợ. \nChỉ hỗ trợ định dạng files: jpg, png, docx, pdf, dwg',
  msg_error_upload_onlyimage: 'File không hỗ trợ. \nChỉ hỗ trợ định dạng files: jpg',
  msg_error_data_need_feedback: 'Dữ liệu có lỗi, vui lòng liên hệ bộ phận Website để được xử lý. Cảm ơn.',
  msg_success_change_avatar: 'Đổi hình đại diện thành công',
  msg_success_upload: 'Upload thành công',
  msg_success_add: 'Thêm thành công',
  msg_success_remove: 'Xóa thành công',
  msg_success_save: 'Lưu thành công',    
  search:'Tìm kiếm',
  text_dtc:'Đội Thi Công',
  text_enter_keyword:'Nhập từ khóa',//Done
  text_groupwpg:'Group WPG', 
  text_hi:'Chào',
  text_optylead:'Opty Lead',
  text_success: 'thành công',
  text_task:'task',
  text_welcome:'Welcome!',
  text_addnew: 'Thêm mới',
  text_all: 'Tất cả',
  text_true: 'True',
  text_false: 'False',
  text_type_pw:'Nhập mật khẩu',
  text_type_user:'Nhập tài khoản',
  text_managerfile: 'Quản lý file', 
  text_noavatar: 'Chưa có hình đại diện',
  text_changeav: 'Thay đổi ảnh đại diện',
  text_openfolder: 'Mở thư mục gốc',
  text_inputlinkgd: 'Nhập đường dẫn google file',
  text_inputname: 'Nhập tên',       
  text_welcome_hello:'Hello [UserName], have a good day!',
  tab_myprofile:'Thông tin cơ bản',
  tab_changepw:'Đổi mật khẩu',
  tab_avatar: 'Ảnh đại diện',
  tab_allfiles:'Tất cả file',
  tab_addfilefromlink:'Thêm từ Link',
  text_login: 'Đăng nhập',
  text_login_des: 'Nhập tài khoản và mật khẩu để đăng nhập',
  text_display_name: "Tên hiển thị",
  text_first_name: "Tên",
  text_middle_name: "Tên đệm",
  text_last_name: "Họ",
  text_birthday: "Ngày sinh",
  text_phone_number: "Số điện thoại",
  text_address: "Địa chỉ",
  text_current_password: "Mật khẩu hiện tại",
  text_new_password: "Mật khẩu mới",
  text_confirm_password: "Nhập lại mật khẩu"
}

export default Text;