import React from "react";
import MyUITypes from "../../../../components/MyUI/MyUITypes";
import MyLoadingComponent from "../../../../components/MyLoading/MyLoadingComponent";

const MTab = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_tab")
);
const MGrouplistItem = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_grouplist_item")
);
const MCardBill = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_bill")
);
const MCardBillDetail = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_bill_detail")
);
const MToolbar = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_toolbar")
);
const McardProductInBill = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_product_in_bill")
);
const McardCustomer = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_customer")
);
const MCardHistoryPaymentCustomer = React.lazy(() =>
  import(
    "../../../../components/MyUI/TypesMobile/m_card_history_payment_of_customer"
  )
);
const MCardHistoryBillCustomer = React.lazy(() =>
  import(
    "../../../../components/MyUI/TypesMobile/m_card_history_bill_of_customer"
  )
);
const MButton = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_button")
);
const MCardInventory = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_inventory")
);
const McardProductInStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_product_in_stock")
);
const MCheckList = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_checklist")
);
const MCardInventoryDetail = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_inventory_detail")
);
const MCardMaterial = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_material")
);
const MCardDelivery = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_delivery")
);
const MDelivery = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_delivery")
);
const MSelectOneList = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_select_one_list")
);
const MContent = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_content")
);
const MContentTopbar = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_content_topbar")
);
const MContentBottombar = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_content_bottombar")
);
const SpinnerDots = React.lazy(() =>
  import("../../../../components/MyUI/TypesMore/spinner_dots")
);
const MTable = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_table")
);
const MListHorizontal = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_list_horizontal")
);
const MCardSupplier = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_supplier")
);
const MGIAccount = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_gi_account")
);
const MGIForm = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_gi_form")
);
const MGroupList = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_grouplist")
);
const MGIChart = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_gi_chart")
);
const MSelect = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_select")
);
const MGITable = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_gi_table")
);
const MGrid = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_grid")
);
const MMansory = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_mansory")
);
const MCardOrder = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_order")
);
const MCardProductOrder = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_product_order")
);
const MTabMaterial = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_tab_material")
);
const MScanQr = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_scan_qr")
);
const MMaterialTag = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_material_tag")
);
const MArchiveTag = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_archive_tag")
);
const MCheckListSearchInventory = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_checklist_search_inventory")
);
const MCardSearchInventory = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_search_inventory")
);
const ScanBarcodeIframe = React.lazy(() =>
  import("../../../../components/MyUI/TypesMore/scan_barcode_iframe")
);
const MCellPhone = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_cell_mpos_report_contact")
);
const MFormMaterial = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_form_material")
);
const MCardInputStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_inputstock")
);
const MCardProductInputStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_product_inputstock")
);
const MCardMaterialHistory = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_material_history")
);
const MCardBillReport = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_bill_report")
);
const MCardOrderStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_orderstock")
);
const MCardProductOrderStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_product_orderstock")
);
const MModalAddOrderStock = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_modal_add_orderstock")
);
const MFormPreparingStaff = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_form_preparing_staff")
);
const MPageFilter = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_page_filter")
);
const MGoTop = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_goTop")
);
const MCheckListServerSearch = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_checklist_server_search")
);
const ReactPaginationBox = React.lazy(() =>
  import("../../../../components/MyUI/Types/react_pagination_box")
);
const MEmployeeInfo = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_employee_info")
);
const MModalExportFile = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_modal_export_file")
);
const MMaterialList = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_material_list")
);
const MInputSelect = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_input_select")
);
const MCardStation = React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_card_station")
);
const MCardBillDetailPicture= React.lazy(() =>
  import("../../../../components/MyUI/TypesMobile/m_bill_detail_picture")
);

const AppMyUI = {
  init() {
    MyUITypes.addTypes("m_tab", (props) => {
      return <MTab {...props} />;
    });
    MyUITypes.addTypes("m_grouplist_item", (props) => {
      return <MGrouplistItem {...props} />;
    });
    MyUITypes.addTypes("m_card_bill", (props) => {
      return <MCardBill {...props} />;
    });
    MyUITypes.addTypes("m_card_bill_detail", (props) => {
      return <MCardBillDetail {...props} />;
    });
    MyUITypes.addTypes("m_toolbar", (props) => {
      return <MToolbar {...props} />;
    });
    MyUITypes.addTypes("m_card_product_in_bill", (props) => {
      return <McardProductInBill {...props} />;
    });
    MyUITypes.addTypes("m_card_customer", (props) => {
      return <McardCustomer {...props} />;
    });
    MyUITypes.addTypes("m_card_history_payment_of_customer", (props) => {
      return <MCardHistoryPaymentCustomer {...props} />;
    });
    MyUITypes.addTypes("m_card_history_bill_of_customer", (props) => {
      return <MCardHistoryBillCustomer {...props} />;
    });
    MyUITypes.addTypes("m_button", (props) => {
      return <MButton {...props} />;
    });
    MyUITypes.addTypes("m_card_inventory", (props) => {
      return <MCardInventory {...props} />;
    });
    MyUITypes.addTypes("m_card_product_in_stock", (props) => {
      return <McardProductInStock {...props} />;
    });
    MyUITypes.addTypes("m_checklist", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MCheckList {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_select_one_list", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MSelectOneList {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_card_inventory_detail", (props) => {
      return <MCardInventoryDetail {...props} />;
    });
    MyUITypes.addTypes("m_card_material", (props) => {
      return <MCardMaterial {...props} />;
    });
    MyUITypes.addTypes("m_card_delivery", (props) => {
      return <MCardDelivery {...props} />;
    });
    MyUITypes.addTypes("m_delivery", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MDelivery {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_content", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MContent {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_content_topbar", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MContentTopbar {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_content_bottombar", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MContentBottombar {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("spinner_dots", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SpinnerDots {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_table", (props) => {
      return <MTable {...props} />;
    });
    MyUITypes.addTypes("m_list_horizontal", (props) => {
      return <MListHorizontal {...props} />;
    });
    MyUITypes.addTypes("m_card_supplier", (props) => {
      return <MCardSupplier {...props} />;
    });
    MyUITypes.addTypes("m_gi_account", (props) => {
      return <MGIAccount {...props} />;
    });
    MyUITypes.addTypes("m_gi_form", (props) => {
      return <MGIForm {...props} />;
    });
    MyUITypes.addTypes("m_grouplist", (props) => {
      return <MGroupList {...props} />;
    });
    MyUITypes.addTypes("m_gi_chart", (props) => {
      return <MGIChart {...props} />;
    });
    MyUITypes.addTypes("m_select", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MSelect {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_gi_table", (props) => {
      return <MGITable {...props} />;
    });
    MyUITypes.addTypes("m_grid", (props) => {
      return <MGrid {...props} />;
    });
    MyUITypes.addTypes("m_mansory", (props) => {
      return <MMansory {...props} />;
    });
    MyUITypes.addTypes("m_card_order", (props) => {
      return <MCardOrder {...props} />;
    });
    MyUITypes.addTypes("m_card_product_order", (props) => {
      return <MCardProductOrder {...props} />;
    });
    MyUITypes.addTypes("m_tab_material", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MTabMaterial {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_scan_qr", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MScanQr {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_material_tag", (props) => {
      return <MMaterialTag {...props} />;
    });
    MyUITypes.addTypes("m_archive_tag", (props) => {
      return <MArchiveTag {...props} />;
    });
    MyUITypes.addTypes("m_checklist_search_inventory", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MCheckListSearchInventory {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_card_search_inventory", (props) => {
      return <MCardSearchInventory {...props} />;
    });
    MyUITypes.addTypes("scan_barcode_iframe", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <ScanBarcodeIframe {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_cell_mpos_report_contact", (props) => {
      return <MCellPhone {...props} />;
    });
    MyUITypes.addTypes("m_form_material", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MFormMaterial {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_card_inputstock", (props) => {
      return <MCardInputStock {...props} />;
    });
    MyUITypes.addTypes("m_card_product_inputstock", (props) => {
      return <MCardProductInputStock {...props} />;
    });
    MyUITypes.addTypes("m_card_material_history", (props) => {
      return <MCardMaterialHistory {...props} />;
    });
    MyUITypes.addTypes("m_card_bill_report", (props) => {
      return <MCardBillReport {...props} />;
    });
    MyUITypes.addTypes("m_card_orderstock", (props) => {
      return <MCardOrderStock {...props} />;
    });
    MyUITypes.addTypes("m_card_product_orderstock", (props) => {
      return <MCardProductOrderStock {...props} />;
    });
    MyUITypes.addTypes("m_modal_add_orderstock", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MModalAddOrderStock {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_form_preparing_staff", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MFormPreparingStaff {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_page_filter", (props) => {
      return <MPageFilter {...props} />;
    });
    MyUITypes.addTypes("m_goTop", (props) => {
      return <MGoTop {...props} />;
    });
    MyUITypes.addTypes("m_checklist_server_search", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MCheckListServerSearch {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("react_pagination_box", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <ReactPaginationBox {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_employee_info", (props) => {
      return <MEmployeeInfo {...props} />;
    });
    MyUITypes.addTypes("m_modal_export_file", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          {" "}
          <MModalExportFile {...props} />{" "}
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("m_material_list", (props) => {
      return <MMaterialList {...props} />;
    });
    MyUITypes.addTypes("m_input_select", (props) => {
      return <MInputSelect {...props} />;
    });
    MyUITypes.addTypes("m_card_station", (props) => {
      return <MCardStation {...props} />;
    });
    MyUITypes.addTypes("m_bill_detail_picture", (props) => {
      return <MCardBillDetailPicture {...props} />;
    });
  },
};
AppMyUI.init();
export default AppMyUI;
