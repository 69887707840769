import React from 'react'
import MyLayoutTypes from './MyLayoutTypes';
class MyUI extends React.Component {    
  render(){
    const {type} = this.props;
    // console.warn("render MyUI:",this.props,type,MyUITypes.Types);
    if(type && MyLayoutTypes.Types[type]){
      return MyLayoutTypes.Types[type](this.props);
    }
    console.warn("MyLayout no type",this.props);
    return (
      <div></div>
    )
  } 
}

export default MyUI