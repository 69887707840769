import React from "react";

import With_MyUI from "./TypesItem/with_myui";
const Text = React.lazy(() => import("./Types/text"));
const Select = React.lazy(() => import("./Types/select"));
const Select2 = React.lazy(() => import("./Types/select2"));
const Search = React.lazy(() => import("./Types/search"));
const Checkbox = React.lazy(() => import("./Types/checkbox"));
const DateFromTo = React.lazy(() => import("./Types/datefromto"));
const Select2Modal = React.lazy(() => import("./Types/select2_modal"));

const MyTableFilterTypes = {
  addTypes(key, fnUI) {
    if (MyTableFilterTypes.Types[key]) {
      console.warn("Type exist:", key);
    } else {
      MyTableFilterTypes.Types[key] = fnUI;
    }
  },
  Types: {
    test: (props) => {
      return <div>Test</div>;
    },
    with_myui: (props) => {
      return <With_MyUI {...props} />;
    },
    text: (props) => {
      return <Text {...props} />;
    },
    select: (props) => {
      return <Select {...props} />;
    },
    search: (props) => {
      return <Search {...props} />;
    },
    checkbox: (props) => {
      return <Checkbox {...props} />;
    },
    select2: (props) => {
      return <Select2 {...props} />;
    },
    datefromto: (props) => {
      return <DateFromTo {...props} />;
    },
    select2_modal: (props) => {
      return <Select2Modal {...props} />;
    },
  },
};

export default MyTableFilterTypes;
