import React from 'react'
import ComponentEdit from './_edit'
import ComponentAddable from './_addable'
import MyTableCellHelpers from '../MyTableCellHelpers';
import {MyUI} from '../../../apps/Components';
import HApiAxios from '../../../helpers/ApiAxios';
import HUI from '../../../helpers/UI';

import "./styles/common.css"
class MyTableCellExt extends React.Component {    
  getSharedObj=()=>{
    const {row,extra} = this.props;
    // console.warn("getSharedObj:",this._sharedObj);
    if(this._sharedObj){
      return this._sharedObj;
    }
    this._sharedObj = {
      more: MyTableCellHelpers.getMoreOfCell(this),
      fieldName: extra.fieldName,
      fnList: MyTableCellHelpers.getFnList(this),
    }
    return this._sharedObj;
  }
  // renderReadonly=()=>{ return ( <div></div> ) }
  renderContent=()=>{ return ( <div></div> ) }
  renderClearBtn=()=>{}
  renderStatus=()=>{
    const {row,extra} = this.props;
    const {more,fieldName} = this.getSharedObj();
    const errorMsg = null;
    if (row && extra){
      let _fieldName = extra.fieldName;
      let _configEditIcon = null;
      let _canEditFieldEdit = true;
      if(more){ 
        _configEditIcon = MyTableCellHelpers.More.getConfigEditIcon(this);
        _canEditFieldEdit = MyTableCellHelpers.getCanEdit(this,_configEditIcon.fieldName||_fieldName);
      }
      
      // row["_UI_Error"]= {};row["_UI_Error"][_fieldName] = {msg: "test"};
      // row["_UI_Loading"] = {}; row["_UI_Loading"][_fieldName] = true;

      return(
        <div className="mct-status no-print">          
          {
            (errorMsg!=null || (row["_UI_Error"] && row["_UI_Error"][_fieldName]!=null && row["_UI_Error"][_fieldName]["msg"]!=null)) &&
            <MyUI type='tooltip' placement="top" overlay={<div style={{maxWidth:'300px'}}>{errorMsg || row["_UI_Error"][_fieldName]["msg"]}</div>}>
              <div className="mct-status-error" onClick={()=>{
              }}>
                <i className="fa fa-exclamation-triangle ani-heartBeat ani-run infinite"/>
              </div>
            </MyUI>            
          }
          {
            row["_UI_Loading"] && row["_UI_Loading"][_fieldName]==true &&
            <div className="mct-status-saving" onClick={()=>{
            }}>
              <i className="fa fa-spinner fa-spin"/>
            </div>
          }
          {
            more!=null && _configEditIcon!=null && _configEditIcon.using==true && _canEditFieldEdit==true &&
            <ComponentEdit configEditIcon={_configEditIcon} row={row} fieldName={_fieldName} onSubmit={(fieldName,newValue)=>{
              MyTableCellHelpers.callRequestUpdate(this,{newValue:newValue})
            }}/>            
          }   
        </div>
      )
    }
  }
  renderAddable=()=>{
    const {extra,row} = this.props;
    const more = MyTableCellHelpers.getMoreOfCell(this);
    const fnList = MyTableCellHelpers.getFnList(this);
    const configAddable = more && more.configAddable;
    let showAddable = false;
    let isHidden = true;  

    if(configAddable){
      if(configAddable.show!=null){
        showAddable = configAddable.show;
      }
    }

    if(showAddable){
      let fieldName = extra.fieldName;
      let request = MyTableCellHelpers.More.getConfigRequest(this);
      if(request.url || (request.path && request.name)){
        if(row[fieldName]==null||row[fieldName]==""||row[fieldName]=="0"){
          isHidden = false;
        }
        else{
          isHidden = true;
        }
        if(configAddable){      
          if(configAddable.alwayShow==true){
            isHidden = false;
          }
        }
        return(
          <ComponentAddable hidden={isHidden} fnList={fnList} onSubmit={(data, optionText)=>{
            let _queryCommon = MyTableCellHelpers.More.getQuery(this);
            let _source = extra.Type!=null?extra.Type.source:"";        
            let _query = {
              Id: row.Id,
            };
            if(data){
              _query = Object.assign(_query,data,_queryCommon);              
            }
            HApiAxios.generic({
              request: request,
              query: _query,
              successCallBack:(response)=>{
                let _data = response.Data;
                HUI.Toast.showSuccess(response.Msg);
                if(_data && fnList && fnList.fnAddOptions){
                  let _optionsText = optionText;
                  let _optionsValue = response.Data[fieldName];
                  if(configAddable.fOptionsText){
                    _optionsText = _data[configAddable.fOptionsText];
                  }
                  if(configAddable.fOptionsValue){
                    _optionsValue = _data[configAddable.fOptionsValue];
                  }

                  fnList.fnAddOptions({
                    source: _source,
                    value: _optionsValue,
                    text: _optionsText
                  });
                }
                if(fnList && fnList.fnReloadTable){
                  fnList.fnReloadTable();
                }                
              },
              errorCallBack:(error,response)=>{
                HUI.Toast.showError(error);
              }
            })
          }}/>
        )
      }
    }
  }
  render(){    
    const more = MyTableCellHelpers.getMoreOfCell(this);
    const canEdit = MyTableCellHelpers.getCanEdit(this);
    const tooltip = more && more.tooltip || null;

    if(canEdit===false && this.renderReadonly){
      return this.renderReadonly()
    }

    let ui =  (
      <div style={MyTableCellHelpers.getStyleOfCell(this)} className={MyTableCellHelpers.getClassOfCell(this)}>
        {this.renderContent()}
        {this.renderClearBtn()}
        {this.renderStatus()}
        {this.renderAddable()}
      </div>
    )

    if(tooltip){
      return(
        <MyUI type="tooltip" placement="top" overlay={tooltip}>
          {ui}
        </MyUI>
      )
    }
    return ui;
  } 
}

export default MyTableCellExt