import React from 'react';

const TheLaylout = React.lazy(() => import('./containers/TheLayout'));
const LoginPage = React.lazy(() => import('./views/Login/index'));
const ForgotPasswordPage = React.lazy(() => import('./views/ForgotPassword/index'));
const WelcomePage = React.lazy(() => import('./views/Home/index'));
const TestPage = React.lazy(() => import('./views/Test/index'));
const DevModePage = React.lazy(() => import('./views/DevMode/index'));
const DevDashboardPage = React.lazy(() => import('./views/DevDashboard/index'));
const DevLayoutMobile = React.lazy(() => import('./views/Test/MyLayout_Mobile'));

const routes = [
  // { path: '/login', exact: true, name: 'Login', component: LoginPage },
  // { path: '/forgot_password', exact: true, name: 'Forgot Password', component: ForgotPasswordPage },
  { path: '/dev/test', exact: true, name: 'TestPage', component: TestPage },
  { path: '/dev/mode', exact: true, name: 'DevMode', component: DevModePage },
  { path: '/dev/dashboard', exact: true, name: 'DevDashboard', component: DevDashboardPage },
  { path: '/dev/mylayout/mobile', exact: true, name: 'DevLayoutMobile', component: DevLayoutMobile },
  { path: '/', exact: false, name: 'Home', component: TheLaylout},
];

const routes_private = [
  { path: '/welcome', exact: true, name: 'Welcome', component: WelcomePage },
];


const routes_dev = [
  // ...RoutesDev
]

const routes_simulate = [
  { UIUrl:'/mhome', ScreenCode:'PAGE_CLIENT_MHOME', Title:'Screen List', APIName:'SystemTestUnitCase', UIType: "Mobile_Tab", Config: "", RequestData: null, Id:"1"},
  // { UIUrl:'/bills/detail/:BillId', ScreenCode:'DM_SCREEN', Title:'Chi tiết hóa đơn', APIName:'BillDetail', UIType: "Mobile_BillDetail", Config: "", RequestData: null, Id:"2"},
  { UIUrl:'/bills/detail/:BillQRCode', ScreenCode:'ALLIANCE_POS_BILL', Title:'Chi tiết hóa đơn', APIName:'BillDetail', UIType: "Mobile_BillDetail", Config: "", RequestData: null, Id:"2"},
  { UIUrl:'/bills/detail-guid/:GUID', ScreenCode:'DM_SCREEN', Title:'Chi tiết hóa đơn', APIName:'BillDetail', UIType: "Mobile_BillDetail", Config: "", RequestData: null, Id:"2"},
  { UIUrl:'/customers/list', ScreenCode:'DM_SCREEN', Title:'Danh sách khách hàng', APIName:'AlliancePosCustomer_CRM', UIType: "Mobile_List", Config: {Item:{type: "m_card_customer", showSearch: true, configPagination: {using: true, pageSize: 20}}}, RequestData: null, Id:"2"},
  { UIUrl:'/customers/detail/bills/:SupplierId_Only', ScreenCode:'DM_SCREEN', Title:'Ds hóa đơn KH', APIName:'ReportBill', UIType: "Mobile_List", Config: {Item:{type: "m_card_history_bill_of_customer", showSearch: false, Title: 'Hóa đơn'}}, RequestData: null, Id:"2"},
  { UIUrl:'/customers/detail/transactions/:SupplierCode', ScreenCode:'DM_SCREEN', Title:'Ds Chuyển khoản KH', APIName:'ReportCompanyBankAccountTransferByCustomer', UIType: "Mobile_List", Config: {Item:{type: "m_card_history_payment_of_customer", showSearch: false}}, RequestData: null, Id:"2"},
  { UIUrl:'/inventory-check/list', ScreenCode:'DM_SCREEN', Title:'Danh sách phiếu kiểm', APIName:'CheckInventory', UIType: "Mobile_List", Config: {Item:{type: "m_card_inventory", showSearch: true, canEdit: false ,showGetDataTime: true, showAddNew: true, linkDetail: "/inventory-check/edit/detail/[Id]"}}, RequestData: null, Id:"4d1521e4-6f84-4a48-af4e-d734b98ffee9"},  
  { UIUrl:'/inventory-check/list/detail/:CheckInventoryId', ScreenCode:'DM_SCREEN', Title:'Chi tiết phiếu kiểm', APIName:'CheckInventoryDetail', UIType: "Mobile_InventoryDetail", Config: {Item:{cardType: "m_card_product_in_stock", showMore: false, canEdit: false, isCollapse: false}, RequestData: null, Id:"2"}},
  { UIUrl:'/inventory-check/edit/detail/:CheckInventoryId', ScreenCode:'DM_SCREEN', Title:'Chi tiết phiếu kiểm', APIName:'CheckInventoryDetail', UIType: "Mobile_InventoryDetail", Config: {Item:{cardType: "m_card_product_in_stock", showMore: false, canEdit: true, isCollapse: false}, RequestData: null, Id:"2"}},

  { UIUrl:'/page=:configPage', ScreenCode:'DM_SCREEN', Title:'Page', APIName:null, UIType: "generic_url", Config: "", RequestData: null, Id: ""},
  { UIUrl:'/report/api=:api', ScreenCode:'ALLIANCE_REPORT', Title:'Báo cáo', APIName:null, UIType: "Mobile_Table", Config: "", RequestData: null, Id: ""},
  { UIUrl:'/my-profile', ScreenCode:'DM_SCREEN', Title:'Tài khoản của tôi', APIName:'Account/UserInfo', UIType: "Mobile_Profile", Config: "", RequestData: null, Id: "3"},
  { UIUrl:'/order/list', ScreenCode:'ALLIANCE_POS_BILL_MOBILE', Title:'Bán hàng', APIName:'Bill', UIType: "Mobile_List", Config: {Item:{type: "m_card_order", showSearch: true, canEdit: false ,showGetDataTime: true, showAddNew: true, linkDetail: "/order/detail/[IONumber]"}}, RequestData: null, Id:"20af5e78-d666-4d92-93d1-d913c405c46c"},  
  { UIUrl:'/order/detail/:IONumber', ScreenCode:'DM_SCREEN', Title:'Chi tiết đơn hàng', APIName:'Bill_IONumber', UIType: "Mobile_Order", Config: {Item:{cardType: "m_card_product_in_stock", showMore: false, canEdit: true, isCollapse: false}, RequestData: null, Id:"2"}},
  { UIUrl:'/search-inventory', ScreenCode:'DM_SCREEN', Title:'Tra cứu tồn kho', APIName:null, UIType: "Mobile_Search_Inventory", Config: {Item: {showSearch: true}}, RequestData: null, Id:"2"},
  { UIUrl:'/inputstock/list', ScreenCode:'ALLIANCE_INPUTSTOCK_MOBILE', Title:"D.sách phiếu nhập kho", APIName: "InputStock", UIType: "Mobile_List", Config: {isClient: true,Item:{type: "m_card_inputstock", showSearch: true, canEdit: false ,showGetDataTime: true, showAddNew: true, linkDetail: "/inputstock/detail/[Id]"}}, RequestData: {}, Id: ""},
  { UIUrl:'/inputstock/detail/:IOStockId', ScreenCode:'DM_SCREEN', Title:'Chi tiết phiếu nhập kho', APIName:'IOStockDetail_V2', UIType: "Mobile_InputStockDetail", Config: {isClient: true,Item:{cardType: "m_card_product_inputstock", showMore: false, canEdit: true, isCollapse: false}, RequestData: null, Id:"2"}},
  { UIUrl:'/page-generic/config=:configPage', ScreenCode:'DM_SCREEN', Title:null, APIName:null, UIType: "Generic_Url", Config: "", RequestData: null, Id: ""},
  { UIUrl:'/materials/change-history', ScreenCode:'DM_SCREEN', Title:"Lịch sử t.đổi h.hóa", APIName:"ReportMaterialChangePriceHistoryDetail", UIType: "Mobile_List", Config: {
    Item: {
      type: "m_card_material_history", 
      showSearch: true, 
      configPagination: {using: true, pageSize: 20}
    }
  }, RequestData: null, Id: ""},
  { UIUrl:'/materials/list', ScreenCode:'ALLIANCE_POS_MATERIAL_MOBILE', Title:'Danh sách hàng hóa', APIName:'AlliancePosMaterial', UIType: "Mobile_List", Config: {
    isClient: true,
    Item:{
      type: "m_card_material", 
      showSearch: true, 
      showAddNew: true, 
      configPagination: {using: true, pageSize: 20}, 
      configAddNew: {
        Position: "top", 
        CardType: "m_form_material", 
        Type: "modal", 
        APIName: "AlliancePosMaterial/Add"
      },
      buttonListTopbar: [{
        Name: 'Xem lịch sử',
        APIName: null,
        Code: null,
        Color: null,
        Color_BG: "#508304",
        ConfirmMsg: null,
        Form: null,
        Icon: "",
        MethodType: "POST",
        More: null,
        NeedReload: false,
        NeedReloadList: true,
        OpenMode: null,
        RequestData: null,
        Type: "link",
        Url: "/materials/change-history",
      }]
    }}, RequestData: null, Id:""},
    { UIUrl:'/orderstock/list', ScreenCode:'ALLIANCE_ORDERSTOCK_MOBILE', Title:"D.sách phiếu đặt hàng", APIName: "OrderStock", UIType: "Mobile_List", Config: {
      isClient: true,
      Item:{
        type: "m_card_orderstock", 
        showSearch: true, 
        canEdit: false ,
        showGetDataTime: true, 
        showAddNew: true, 
        linkDetail: "/orderstock/detail/[Id]",
        buttonListTopbar: [{
          Name: 'Chọn hàng từ yêu cầu đặt hàng',
          APIName: "OrderStock_CheckList",
          Code: null,
          Color: null,
          Color_BG: "#508304",
          ConfirmMsg: null,
          Form: null,
          Icon: "",
          MethodType: "POST",
          More: {
            modal:{type: "m_modal_add_orderstock", title: "Chọn hàng tạo phiếu đặt hàng NCC"},
          },          
          NeedReload: false,
          NeedReloadList: true,
          OpenMode: null,
          RequestData: null,
          Type: "m_modal",
          Url: null,
        }]
      }}, RequestData: {}, Id: ""},
    { UIUrl:'/orderstock/detail/:OrderStockId', ScreenCode:'DM_SCREEN', Title:'Chi tiết đơn đặt hàng', APIName:'OrderDetail', UIType: "Mobile_OrderStockDetail", Config: {isClient: true,Item:{cardType: "m_card_product_orderstock", showMore: false, canEdit: true, isCollapse: false}, RequestData: null, Id:"2"}},


]
const routes_simulate_public = [
  { UIUrl:'/dev/a', ScreenCode:'DevA', Title:'DevA', APIName:'DevA', UIType: "Test", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/login', ScreenCode:'LOGIN', Title:'Login', APIName:null, UIType: "With_MyUI", ConfigObj: {type: "common_login"}, RequestData: null, Id:"1"},
  { UIUrl:'/forgot_password', ScreenCode:'FORGOT_PASSWORD', Title:'Forgot password', APIName:null, UIType: "With_MyUI", ConfigObj: {type: "common_forgot_password"}, RequestData: null, Id:"1"},
  { UIUrl:'/employee/:Guid', ScreenCode:'EMPLOYEE', Title:'AjumaGarden - Thông tin nhân viên', APIName:null, UIType: "With_MyUI", ConfigObj: {type: "m_employee_info"}, RequestData: null, Id:"1"},
  { UIUrl:'/reset_password/PasswordKey=:PasswordKey', ScreenCode:'RESET_PASSWORD', Title:'Reset password', APIName:null, UIType: "With_MyUI", ConfigObj: {type: "common_reset_password"}, RequestData: null, Id:"1"},
]

const routes_test = [
]

export {
  routes,
  routes_private,
  routes_dev,
  routes_simulate,
  routes_simulate_public,
  routes_test
}
