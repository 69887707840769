import React from 'react'
import MyLayoutTypes from '../../../../components/MyLayout/MyLayoutTypes';
import MyLoadingComponent from '../../../../components/MyLoading/MyLoadingComponent';

const MyLayout_Mobile = React.lazy(() => import('../../../../components/MyLayout/TypesMobile/mobile'));


const AppMyLayout = {
  init(){
    MyLayoutTypes.addTypes('mobile',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <MyLayout_Mobile {...props}/> </React.Suspense> )
    });
  }
}
AppMyLayout.init();
export default AppMyLayout;