
import React from 'react'
import MyTableCellExt from './_ext';
import MyTableCellHelpers from '../MyTableCellHelpers';
import MyTableCellReadOnly from './readonly';
import {MyUI} from '../../../apps/Components';

class MyTableCell_Textarea extends MyTableCellExt {
  _getMyClass=()=>{
    return "mct-textarea";
  } 
  renderReadonly=()=>{    
    return (
      <MyTableCellReadOnly {...this.props}/>
    )
  }   
  renderContent=()=>{
    const {row,cell} = this.props;
    const more = MyTableCellHelpers.getMoreOfCell(this);
    const disabled = !MyTableCellHelpers.getCanEdit(this);
    const className = MyTableCellHelpers.getClassOfCell(this);
    const placeholder = more && more.placeholder || '';

    return (
      <MyUI 
        type="input_textarea" 
        defaultValue={cell} 
        style={MyTableCellHelpers.getStyleOfCell(this)}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        onBlur={(ev,newValue)=>{          
          MyTableCellHelpers.callRequestUpdate(this,{newValue});
        }}
      />
    )
  } 
}

export default MyTableCell_Textarea