const Text = {
  btn_cancel:'Cancel',
  btn_edit:'Edit',
  btn_change_pw: 'Change password',//Done
  btn_close:'Close',
  btn_confirm:'Confirm',
  btn_choosefile:'Select files',
  btn_uploadingfile:'Uploading files...',
  btn_delete:'Delete',
  btn_new:'Create new',
  btn_ok:'OK',
  btn_save:'Save',
  btn_saving:'Saving...',
  btn_login: 'Login',
  btn_forgotpw: 'Forgot password?',
  btn_logout: 'Logout',
  btn_uploadfile: 'Upload file',
  btn_refresh_cache: 'Refresh Cache',
  btn_clear_local_cache: 'Clear Local Cache',
  btn_submit:'Submit',
  btn_tryagain:'Try again',//Done
  btn_bypass:'Close',//Done
  btn_getstarted:'Get Started',
  btn_pickdatefromto: 'Select date',
  btn_orderlistreport: 'Order List',
  btn_homepage: 'Home',
  btn_reload: 'Reload',
  btn_myprofile: 'My Profile',
  chart_chose_chart:'Select type chart',
  chart_chose_info:'Select type info',
  confirm_create_new:'Are you sure want to add new?',
  confirm_delete:'Are you sure to delete?',
  create_new: 'Create new',
  msg_confirm_success: 'Successfully',//Done
  msg_email_send: 'Email sent',
  msg_input_check_before_send: 'Please fill all infomation',//Done
  msg_input_please: 'Please fill all infomation',//Done
  msg_list_files_onsite: 'Files (OnSite)',
  msg_list_files_plan: 'Files (Plan)',
  msg_list_files: 'Files',
  msg_loading: 'Loading...',//Loading...
  msg_loading_please_waiting: 'Loading..., please waiting!',
  msg_nodata: 'No data available!',//Done
  msg_nophoto: 'No photo!',//Done
  msg_confirm_file: 'Are you sure?',
  msg_pw_confirm_wrong: 'Confirm password wrong!',
  msg_pw_new_wrong: 'Your new password need different old password',
  msg_pw_enter_new: 'Please enter your new password',
  msg_pw_input_confirm: 'Confirm new password:',//Done
  msg_pw_input_new: 'New password:',//Done
  msg_error_login_google: 'Link google failed! Try logout google account and login again.',
  msg_error:'Has some error!',
  msg_error_get_options:'Error get Options!',
  msg_error_get_list:'Error get list!',
  msg_error_upload_no_support: 'File not supported. \nSupported files: jpg, png, docx, pdf, dwg',
  msg_error_upload_onlyimage: 'File not supported. \nnSupported files: jpg',
  msg_error_data_need_feedback: 'Data error! Please contact admin system. Thanks.',
  msg_success_change_avatar: 'Change avatar successful',
  msg_success_upload: 'Upload successful',
  msg_success_add: 'Successful',
  msg_success_remove: 'Successful',
  msg_success_save: 'Successful',    
  search:'Search',
  text_dtc:'Đội Thi Công',
  text_enter_keyword:'Enter your keyword',//Done
  text_groupwpg:'Group WPG', 
  text_hi:'Hi',
  text_optylead:'Opty Lead',
  text_success: 'Successful',
  text_task:'task',
  text_welcome:'Welcome!',
  text_addnew: 'Add new',
  text_all: 'All',
  text_true: 'True',
  text_false: 'False',
  text_type_pw:'Password',
  text_type_user:'Username',
  text_managerfile: 'Manage file', 
  text_noavatar: 'No avatar',
  text_changeav: 'Change avatar',
  text_openfolder: 'Open folder',
  text_inputlinkgd: 'Enter your link google file',
  text_inputname: 'Name',       
  text_welcome_hello:'Hello [UserName], have a good day!',
  tab_myprofile:'Profile',
  tab_changepw:'Change password',
  tab_avatar: 'Avatar',
  tab_allfiles:'All files',
  tab_addfilefromlink:'Add from Link',
  text_login: 'Login',
  text_login_des: 'Sign In to your account',
  text_display_name: "Display Name",
  text_first_name: "First Name",
  text_middle_name: "Middle Name",
  text_last_name: "Last Name",
  text_birthday: "Date Of Birth",
  text_phone_number: "Phone Number",
  text_address: "Address",
  text_current_password: "Current Password",
  text_new_password: "New Password",
  text_confirm_password: "Confirm Password"
}

export default Text;